import { ContentState, RawDraftContentState } from 'draft-js';
import { omit } from 'lodash-es';
import { defineMessages } from 'react-intl';

import {
  EDUCATION_LEVEL,
  ID_COUNTRY_LOCATION,
  JOB_STATUS,
  JOB_TYPE,
  JobApplicationMethodOption,
  VN_COUNTRY_LOCATION,
  WorkArrangementOption,
} from '../../../common/constants';
import {
  HierarchicalLocation,
  Industry,
  JobBenefit,
  JobScreeningQuestionSelectedQuestions,
  JobSkill,
  Skill,
} from '../../../common/interfaces/entities';

export const getJobDescriptionTemplateBasedOnCountry = (
  countryCode: string
) => {
  switch (countryCode.toUpperCase()) {
    case 'ID':
      return 'https://docs.google.com/document/d/12EC32Nq9ZDQ_FjlSLkqMAg6l8R_fBNJJa-4V9Od0d3E';
    default:
      return 'https://goo.gl/wrCqRY';
  }
};

type Nullable<T> = { [K in keyof T]: T[K] | null };

export enum JobStatus {
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  IN_REVIEW = 'IN_REVIEW',
}

export enum Seniority {
  LESS_THAN_A_YEAR = '0-1',
  ONE_TO_THREE = '1-3',
  THREE_TO_FIVE = '3-5',
  FIVE_TO_TEN = '5-10',
  TEN_PLUS = '10-50',
  NO_PREFERENCE = '0-50',
}

export const jobStatusLabels = defineMessages({
  [JobStatus.DRAFT]: {
    id: 'text-draft',
    defaultMessage: 'Draft',
  },
  [JobStatus.OPEN]: {
    id: 'text-active',
    defaultMessage: 'Active',
  },
  [JobStatus.CLOSED]: {
    id: 'interactive-inactive',
    defaultMessage: 'Inactive',
  },
  [JobStatus.IN_REVIEW]: {
    id: 'text-in-review',
    defaultMessage: 'In Review',
  },
});

export const GenderPreferenceForJob = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  NO_PREFERENCE: 'NO_PREFERENCE',
} as const;

export type GenderPreferenceForJob =
  (typeof GenderPreferenceForJob)[keyof typeof GenderPreferenceForJob];

export const footerMessages = defineMessages({
  saveChanges: {
    id: 'text-changes-need-saved',
    defaultMessage: 'Changes need to be saved',
  },
});

export const subscriptionMessages = defineMessages({
  hardLimitReachedTitle: {
    id: 'text-can-hire-count-roles',
    defaultMessage:
      'You can only hire {count, plural, =1 {1 role at a time} other {{count} roles at the same time}}',
  },
  upgradeSubscriptionPostJobDescription: {
    id: 'text-upgrade-subscription-to-post',
    defaultMessage:
      'Upgrade your subscription plan to post this job and hire more than {count, plural, =1 {1 role} other {{count} roles}} at the same time.',
  },
  upgradeSubscriptionReactivateJobDescription: {
    id: 'text-upgrade-subscription-to-reactivate',
    defaultMessage:
      'Upgrade your subscription plan to reactivate this job and hire more than {count, plural, =1 {1 role} other {{count} roles}} at the same time.',
  },
  deactivateJobInfo: {
    id: 'text-deactivate-if-made-hiring',
    defaultMessage:
      'You can also deactivate any of your active jobs if you have made a hiring.',
  },
  saveJobDraftInfo: {
    id: 'text-now-job-saved-draft',
    defaultMessage: 'For now, this job is saved as <bold>a draft</bold>.',
  },
  upgradeNow: {
    id: 'interactive-upgrade-now',
    defaultMessage: 'Upgrade Now',
  },
  manageActiveJobs: {
    id: 'interactive-manage-active-jobs',
    defaultMessage: 'Manage Active Jobs',
  },
  interactiveCancel: {
    id: 'interactive-cancel',
    defaultMessage: 'Cancel',
  },
});

export const CustomQuestionPlainTexts = {
  CUSTOM_PLAIN_TEXT_A: 'CUSTOM_PLAIN_TEXT_A',
  CUSTOM_PLAIN_TEXT_B: 'CUSTOM_PLAIN_TEXT_B',
  CUSTOM_PLAIN_TEXT_C: 'CUSTOM_PLAIN_TEXT_C',
} as const;

export type CustomQuestionPlainTexts =
  (typeof CustomQuestionPlainTexts)[keyof typeof CustomQuestionPlainTexts];

export const SelectedQuestionsFormState = {
  ...omit(JobScreeningQuestionSelectedQuestions, 'CUSTOM_PLAIN_TEXT'),
  ...CustomQuestionPlainTexts,
};

export type SelectedQuestionsFormState =
  (typeof SelectedQuestionsFormState)[keyof typeof SelectedQuestionsFormState];

export type ScreeningQuestionFormState = {
  selectedQuestions?: SelectedQuestionsFormState[];
  introVideoPreferredLanguage?: string;
  documentAndCertificates?: {
    id: string;
    name: string;
  }[];
  industries?: Industry[];
  customQuestionPlainTextA?: string;
  customQuestionPlainTextB?: string;
  customQuestionPlainTextC?: string;
};

export const customQuestionPlainTextToFieldName: Record<
  CustomQuestionPlainTexts,
  | 'customQuestionPlainTextA'
  | 'customQuestionPlainTextB'
  | 'customQuestionPlainTextC'
> = {
  [CustomQuestionPlainTexts.CUSTOM_PLAIN_TEXT_A]: 'customQuestionPlainTextA',
  [CustomQuestionPlainTexts.CUSTOM_PLAIN_TEXT_B]: 'customQuestionPlainTextB',
  [CustomQuestionPlainTexts.CUSTOM_PLAIN_TEXT_C]: 'customQuestionPlainTextC',
};

export const MAX_CUSTOM_PLAIN_TEXT_LENGTH = 200;

export interface JobFormState {
  title: string;
  type: (typeof JOB_TYPE)[keyof typeof JOB_TYPE];
  JobCategoryId?: number;
  categoryName: string;
  workArrangementOption: WorkArrangementOption;
  CityId: number | null;
  CountryCode: string;
  countryName: string;
  acceptsForeignApplications: boolean;
  description: ContentState;
  descriptionRaw: RawDraftContentState;
  seniority?: Seniority;
  skills: JobSkill[];
  addBonus: boolean;
  salaryMode: BonusFrequency;
  minSalaryAmount: number;
  maxSalaryAmount: number | null;
  minBonusAmount: number;
  maxBonusAmount: number;
  shouldShowSalary: boolean;
  CurrencyCode: string;
  jobDraftId?: string;
  isEdit?: boolean;
  isCopy?: boolean;
  interviewProcess?: ContentState;
  interviewProcessRaw?: RawDraftContentState | null;
  shouldPublishOnLinkedin?: boolean;
  benefits?: JobBenefit[];
  receiverIds: string[];
  HierarchicalJobCategoryId?: string;
  HierarchicalJobCategoryName?: string;
  provinceLocation: HierarchicalLocation | null;
  cityLocation: HierarchicalLocation | null;
  districtLocation: HierarchicalLocation | null;
  districtOptions?: HierarchicalLocation[];
  educationLevel: EDUCATION_LEVEL;
  originalHierarchicalJobCategoryId?: string | null;
  originalDistrictId?: string | null;
  CreatorId?: string;
  originalCreatorId?: string | null;
  isSuggestedSalaryApplied?: boolean;
  userChangedSalary?: boolean;
  isWhitelistedCountry?: boolean;
  minAge: number | null;
  maxAge: number | null;
  noAgeLimit: boolean;
  gender: GenderPreferenceForJob;
  unfilteredSkills: Skill[];
  haveUnfilteredSkills: boolean;
  isLocationRequiredForCandidate?: boolean | null;
  isEducationRequiredForCandidate?: boolean | null;
  isResumeRequiredForCandidate?: boolean | null;
  step: JobPostingPageStep;
  screeningQuestions?: ScreeningQuestionFormState | null;
  screeningQuestionsEnabled?: boolean;
  isProfilePicRequiredForCandidate?: boolean | null;
  jobApplicationMethodOption: JobApplicationMethodOption;
  externalApplyURL?: string | null;
}

export enum SalaryType {
  BASIC = 'BASIC',
  BONUS = 'BONUS',
}

export interface SalaryBasic {
  salaryType: SalaryType.BASIC;
  salaryMode: BonusFrequency.MONTH;
  minAmount: number;
  maxAmount: number | null;
  CurrencyCode: string;
}

export interface SalaryBonus {
  salaryType: SalaryType.BONUS;
  salaryMode: BonusFrequency;
  minAmount: number;
  maxAmount: number;
  CurrencyCode: string;
}

type ScreeningQuestionPayload = {
  selectedQuestions: JobScreeningQuestionSelectedQuestions[];
  introVideoPreferredLanguage?: string;
  documentAndCertificateIds?: string[];
  industryIds?: number[];
  customQuestionPlainTexts?: string[];
};

export interface JobPostingPayload {
  status: JobStatus;
  CompanyId: string;
  numberOfHires: number;
  receiverIds: string[];
  title: string;
  type: (typeof JOB_TYPE)[keyof typeof JOB_TYPE];
  JobCategoryId: number;
  workArrangementOption: WorkArrangementOption;
  CityId: number;
  CountryCode: string;
  acceptsForeignApplications: boolean;
  descriptionRaw: RawDraftContentState;
  salaries: [SalaryBasic, SalaryBonus?];
  skills: { id: string; mustHave: boolean }[];
  shouldShowSalary: boolean;
  minYearsOfExperience?: number;
  maxYearsOfExperience?: number;
  interviewProcessRaw?: RawDraftContentState | null;
  shouldPublishOnLinkedin?: boolean;
  benefits?: Omit<JobBenefit, 'label' | 'logo' | 'title'>[];
  shouldShowBenefits?: boolean;
  educationLevel: EDUCATION_LEVEL;
  HierarchicalJobCategoryId: string;
  CreatorId?: string;
  minAge: number | null;
  maxAge: number | null;
  gender: Omit<GenderPreferenceForJob, 'NO_PREFERENCE'> | null;
  jobDraftId?: string;
  screeningQuestions?: ScreeningQuestionPayload;
  externalApplyURL?: string | null;
}

export type JobDraftFormState = Partial<Nullable<JobFormState>>;
export type JobDraftPostingPayload = Partial<Nullable<JobPostingPayload>>;

export const JOB_POSTING_ID = 'jobv2-job-posting';
export const JOB_EDITING_ID = 'jobv2-job-editing';
export const JOB_ENHANCE_ID = 'jobv2-job-enhance';

export const DEFAULT_NUMBER_OF_HIRES = 1;

export const DEFAULT_JOB_STATUS = JOB_STATUS.OPEN;

export enum BonusFrequency {
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  PROJECT = 'PROJECT',
}

export enum SalaryPaymentType {
  RANGE = 'RANGE',
  BASE = 'BASE',
}

export const JobEditTabs = {
  DETAILS: 'DETAILS',
  SCREENING_QUESTIONS: 'SCREENING_QUESTIONS',
  ENHANCE: 'ENHANCE',
} as const;

export type JobEditTabs = (typeof JobEditTabs)[keyof typeof JobEditTabs];

type CountryInfo = {
  id: string;
  name: string;
  code: string;
  currency: string;
};

export const INDONESIA_COUNTRY = { ...ID_COUNTRY_LOCATION, currency: 'IDR' };
export const VIETNAM_COUNTRY = { ...VN_COUNTRY_LOCATION, currency: 'VND' };

export const MULTI_LEVEL_LOCATION_ENABLED_COUNTRIES = [
  INDONESIA_COUNTRY.code,
  VIETNAM_COUNTRY.code,
];

export const COUNTRY_DATA: Record<string, CountryInfo> = {
  ID: INDONESIA_COUNTRY,
  VN: VIETNAM_COUNTRY,
};

const getDefaultCountry = (countryCode: string) =>
  COUNTRY_DATA[countryCode] ?? INDONESIA_COUNTRY;

export const getDefaultMinSalaryAmount = (currencyCode?: string): number =>
  currencyCode === INDONESIA_COUNTRY.currency ? 1000000 : 0;

type OverridenValues = Record<string, any>;

export const makeRequiredFormDefaultValues = (
  countryCode: string,
  overridenValues: OverridenValues
): Partial<JobFormState> => ({
  type: JOB_TYPE.FULL_TIME,
  workArrangementOption: WorkArrangementOption.ONSITE,
  CountryCode: getDefaultCountry(countryCode).code,
  countryName: getDefaultCountry(countryCode).name,
  shouldShowSalary: true,
  CurrencyCode: getDefaultCountry(countryCode).currency,
  addBonus: false,
  salaryMode: BonusFrequency.MONTH,
  isEdit: false,
  isCopy: false,
  skills: [],
  minSalaryAmount: getDefaultMinSalaryAmount(
    getDefaultCountry(countryCode).currency
  ),
  maxSalaryAmount: null,
  title: '',
  provinceLocation: null,
  cityLocation: null,
  districtLocation: null,
  isWhitelistedCountry: true,
  noAgeLimit: true,
  minAge: null,
  maxAge: null,
  gender: GenderPreferenceForJob.NO_PREFERENCE,
  unfilteredSkills: [],
  haveUnfilteredSkills: false,
  shouldPublishOnLinkedin: countryCode === VIETNAM_COUNTRY.code,
  step: JobPostingPageStep.JOB_INFO,
  jobApplicationMethodOption: JobApplicationMethodOption.GLINTS,
  ...overridenValues,
});

export interface DraftURLParams {
  draftId: string;
}

export type CompanyMembersOptions = {
  value: string;
  label: string;
  email: string;
  isDeletable: boolean;
  userId: string;
  profilePic: string | null;
  role?: string;
};

export enum REOPEN_JOB_PAGE {
  DASHBOARD = 'DASHBOARD',
  JOB_EDIT_PAGE = 'JOB_EDIT_PAGE',
  MANAGE_CANDIDATES = 'MANAGE_CANDIDATES',
}

export const CLOSE_JOB_PAGE = {
  DASHBOARD: 'DASHBOARD',
  JOB_EDIT_PAGE: 'JOB_EDIT_PAGE',
  MANAGE_CANDIDATES: 'MANAGE_CANDIDATES',
} as const;

export type CLOSE_JOB_PAGE =
  (typeof CLOSE_JOB_PAGE)[keyof typeof CLOSE_JOB_PAGE];

export enum JOB_EDIT_CHANGE_TYPE {
  JOB_ROLE,
  JOB_LOCATION,
}

export const JOB_POSTING_EDUCATION_LEVEL = {
  PRIMARY_SCHOOL: 'PRIMARY_SCHOOL',
  SECONDARY_SCHOOL: 'SECONDARY_SCHOOL',
  HIGH_SCHOOL: 'HIGH_SCHOOL',
  DIPLOMA: 'DIPLOMA',
  COLLEGE_DEGREE: 'COLLEGE_DEGREE',
  BACHELOR_DEGREE: 'BACHELOR_DEGREE',
  MASTER_DEGREE: 'MASTER_DEGREE',
  DOCTORATE: 'DOCTORATE',
  NO_PREFERENCE: 'NO_PREFERENCE',
};

export const JOB_POSTING_EDUCATION_LEVEL_MESSAGES = defineMessages({
  [JOB_POSTING_EDUCATION_LEVEL.PRIMARY_SCHOOL]: {
    id: 'interactive-primary-school',
    defaultMessage: 'Primary School',
  },
  [JOB_POSTING_EDUCATION_LEVEL.SECONDARY_SCHOOL]: {
    id: 'interactive-secondary-school',
    defaultMessage: 'Secondary School',
  },
  [JOB_POSTING_EDUCATION_LEVEL.HIGH_SCHOOL]: {
    id: 'interactive-high-vocational-school',
    defaultMessage: 'High School/ Vocational School',
  },
  [JOB_POSTING_EDUCATION_LEVEL.DIPLOMA]: {
    id: 'interactive-diploma',
    defaultMessage: 'Diploma',
  },
  [JOB_POSTING_EDUCATION_LEVEL.COLLEGE_DEGREE]: {
    id: 'interactive-college-degree',
    defaultMessage: 'College Degree',
  },
  [JOB_POSTING_EDUCATION_LEVEL.BACHELOR_DEGREE]: {
    id: 'interactive-bachelors-degree',
    defaultMessage: "Bachelor's Degree",
  },
  [JOB_POSTING_EDUCATION_LEVEL.MASTER_DEGREE]: {
    id: 'interactive-master-degree',
    defaultMessage: "Master's Degree",
  },
  [JOB_POSTING_EDUCATION_LEVEL.DOCTORATE]: {
    id: 'interactive-doctorate',
    defaultMessage: 'Doctorate',
  },
});

export const JobPostingSeniority = {
  LESS_THAN_A_YEAR: '0-1',
  ONE_TO_THREE: '1-3',
  THREE_TO_FIVE: '3-5',
  FIVE_TO_TEN: '5-10',
  TEN_PLUS: '10-50',
  NO_PREFERENCE: '0-50',
};

export type CountrySelect = {
  value: string;
  label: string;
  currencyCode: string;
};

export const MIN_AGE = 16;
export const MAX_AGE = 65;

export const JobPostingPageStep = {
  JOB_INFO: 1,
  SCREENING_QUESTIONS: 2,
} as const;

export type JobPostingPageStep =
  (typeof JobPostingPageStep)[keyof typeof JobPostingPageStep];
