import { MutableRefObject, useCallback, useContext, useRef } from 'react';

import { MainHeaderHeightContext } from '../../context';

const scrollIntoRefView = (
  ref: MutableRefObject<HTMLDivElement | null>,
  offset: number
) => {
  if (!ref?.current) {
    return;
  }

  const yPosition =
    ref.current.getBoundingClientRect().top + window.pageYOffset + offset;
  window.scrollTo({ top: yPosition, behavior: 'smooth' });
};

export default function useScrollToRef(offset = 0) {
  const ref = useRef<HTMLDivElement | null>(null);
  const scrollToRef = useCallback(
    () => scrollIntoRefView(ref, offset),
    [ref, offset]
  );

  return [ref, scrollToRef] as const;
}

export const useScrollToRefPastNavBar = () => {
  const mainHeaderHeight = useContext(MainHeaderHeightContext);
  return useScrollToRef(-mainHeaderHeight);
};
