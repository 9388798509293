import { includes, values } from 'lodash-es';
import { QueryParamConfig, StringParam } from 'use-query-params';

import { ApplicationStatus } from '../../common/constants';
import { FilterApplicationSource } from './constants';

export const FilterApplicationSourceQueryParam: QueryParamConfig<
  FilterApplicationSource | null | undefined,
  FilterApplicationSource
> = {
  encode: StringParam.encode,
  decode: value => {
    const appSource = StringParam.decode(value);

    if (!appSource || !includes(values(FilterApplicationSource), appSource)) {
      return FilterApplicationSource.ALL_CANDIDATES;
    }

    return appSource as FilterApplicationSource;
  },
};

export const FilterApplicationStatusQueryParam: QueryParamConfig<
  ApplicationStatus | null | undefined,
  ApplicationStatus
> = {
  encode: StringParam.encode,
  decode: value => {
    const appStatus = StringParam.decode(value);

    if (!appStatus || !includes(values(ApplicationStatus), appStatus)) {
      return ApplicationStatus.NEW;
    }

    return appStatus as ApplicationStatus;
  },
};
