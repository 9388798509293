import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { uniq, uniqBy } from 'lodash-es';

import { updateChannelsUnreadCountCache } from '../../../../common/hooks/requests/useGetChannelsUnreadCount';
import { createGlobalState } from '../../../../common/react-query/createGlobalState';
import { getMemberById, isMessageAlreadyRead } from '../../helpers';
import { GlintsChatChannel } from '../../types/channel';
import { GlintsChatMessage } from '../../types/message';
import { mergeChannel, mergeMessageList } from '../helpers';

type ChannelListState = Record<string, GlintsChatChannel>;
type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;
const useGetChannelListState = createGlobalState<ChannelListState>(
  'glintsChatChannelList',
  {}
);

export const useChannelListState = () => {
  const queryClient = useQueryClient();
  const { data, setData, resetData } = useGetChannelListState();

  const getChannelById = (channelId: string): GlintsChatChannel | null =>
    data?.[channelId] || null;

  const getChannelsByIds = (channelIds: string[]): GlintsChatChannel[] => {
    const uniqueChannelIds = uniq(channelIds);
    return uniqueChannelIds
      .map(channelId => getChannelById(channelId))
      .filter(Boolean) as GlintsChatChannel[];
  };

  const updateChannels = (
    channels: GlintsChatChannel[],
    removePendingMessages?: boolean
  ) => {
    setData(currentData => {
      const newData = { ...currentData };

      channels.forEach(channel => {
        newData[channel.id] = mergeChannel(
          currentData?.[channel.id] || channel,
          channel,
          removePendingMessages
        );
      });

      return newData;
    });
  };

  const updateChannelMessages = (
    channelId: string,
    incomingMessages:
      | GlintsChatMessage[]
      | ((prevMessages: GlintsChatMessage[]) => GlintsChatMessage[]),
    mode: 'replace' | 'merge' = 'merge'
  ) => {
    setData(currentData => {
      const channel = currentData?.[channelId];

      if (!channel) return;

      const transformedIncomingMessages =
        typeof incomingMessages === 'function'
          ? incomingMessages(channel.messageList)
          : incomingMessages;

      const newMessageList =
        mode === 'replace'
          ? transformedIncomingMessages
          : mergeMessageList({
              oldMessageList: channel.messageList,
              newMessageList: transformedIncomingMessages,
            });

      return {
        ...currentData,
        [channelId]: {
          ...channel,
          messageList: newMessageList,
        },
      };
    });
  };

  const updateChannelById = useCallback(
    (
      channelId: string,
      newChannel: (
        prevChannel: GlintsChatChannel
      ) => Partial<GlintsChatChannel>,
      channelInfoUpdateStrategy: 'replace' | 'merge' = 'merge'
    ) => {
      setData(currentData => {
        const channel = currentData?.[channelId];
        if (!channel) return currentData;

        const updatedChannel =
          channelInfoUpdateStrategy === 'replace'
            ? (newChannel(channel) as GlintsChatChannel)
            : { ...channel, ...newChannel(channel) };

        return {
          ...currentData,
          [channelId]: updatedChannel,
        };
      });
    },
    [setData]
  );

  const addChannelToList = useCallback(
    (channel: GlintsChatChannel) => {
      setData(currentData => ({
        ...currentData,
        [channel.id]: channel,
      }));
    },
    [setData]
  );

  const updateMessage = (
    channelId: string,
    messageId: string,
    newMessage:
      | Partial<GlintsChatMessage>
      | ((message: GlintsChatMessage) => Partial<GlintsChatMessage>),
    mode: 'replace' | 'merge' = 'merge'
  ) => {
    updateChannelById(channelId, currentChannel => {
      const messageList = currentChannel.messageList.map(message => {
        if (message.id !== messageId) return message;
        const updateMessage =
          typeof newMessage === 'function' ? newMessage(message) : newMessage;
        const processedMessage =
          mode === 'replace'
            ? (updateMessage as GlintsChatMessage)
            : { ...message, ...updateMessage };

        return processedMessage as GlintsChatMessage;
      });

      // there will be a concurency cases on sending a message, where this update function
      // run after the WS event added the newly sent message to the cache, in that case
      // there will be two message with the same id, so we need to remove the duplicate
      const uniqMessageList = uniqBy(messageList, message => message.id);

      return {
        ...currentChannel,
        messageList: uniqMessageList,
      };
    });
  };

  const updateChannelAndMessage = (props: {
    channelId: string;
    messageId: string;
    newChannel: (
      currentChannel: GlintsChatChannel
    ) => DeepPartial<GlintsChatChannel>;
    newMessage: (
      currentChannel: GlintsChatMessage
    ) => DeepPartial<GlintsChatMessage>;
  }) => {
    setData(currentData => {
      const channel = currentData?.[props.channelId];
      if (!channel) return currentData;
      const channelDataToUpdate = props.newChannel?.(channel) || {};
      const updateChannelList = channel.messageList.map(message => {
        if (message.id !== props.messageId) return message;
        const messageToUpdate = props.newMessage?.(message) || {};
        return {
          ...message,
          ...messageToUpdate,
        };
      });
      return {
        ...currentData,
        [props.channelId]: {
          ...{
            ...channel,
            ...channelDataToUpdate,
          },
          messageList: updateChannelList,
        } as GlintsChatChannel,
      };
    });
  };

  const addMessageToChannel = (props: {
    channelId: string;
    newMessage: GlintsChatMessage;
  }) => {
    setData(currentData => {
      const channel = currentData?.[props.channelId];
      if (!channel) return currentData;
      return {
        ...currentData,
        [props.channelId]: {
          ...channel,
          messageList: [props.newMessage, ...channel.messageList],
        } as GlintsChatChannel,
      };
    });
  };

  const removeMessage = (channelId: string, messageId: string) => {
    updateChannelById(channelId, currentChannel => {
      const messageList = currentChannel.messageList.filter(
        message => message.id !== messageId
      );
      return {
        ...currentChannel,
        messageList,
      };
    });
  };

  const markMessageAsRead = useCallback(
    (channelId: string, messageId: string, currentUserId: string) => {
      updateChannelById(channelId, channel => {
        const currentUser = getMemberById(channel.members, currentUserId);
        if (!currentUser) return channel;

        const currentUserLastReadMessageId = currentUser.lastReadID;
        if (currentUserLastReadMessageId === messageId) {
          return channel;
        }

        const [isMessageAlreadyReadCheck, unreadMessageIndex] =
          isMessageAlreadyRead(channel, messageId, currentUserId);

        if (unreadMessageIndex < 0) {
          return channel;
        }

        // Prevent marking message as read if the message already read
        if (isMessageAlreadyReadCheck) {
          return channel;
        }

        const newMembers = channel.members.map(member =>
          member.id === currentUserId
            ? {
                ...member,
                lastReadID: messageId,
              }
            : member
        );

        // Update cache from /channels/unread endpoint
        updateChannelsUnreadCountCache(
          queryClient,
          channelId,
          unreadMessageIndex
        );

        return {
          ...channel,
          members: newMembers,
          unreadNumber: unreadMessageIndex,
        };
      });
    },
    [queryClient, updateChannelById]
  );

  const updateChannelMemberLastReadId = useCallback(
    (channelId: string, messageId: string, memberId: string) => {
      updateChannelById(channelId, channel => {
        const newMembers = channel.members.map(member =>
          member.id === memberId
            ? {
                ...member,
                lastReadID: messageId,
              }
            : member
        );

        return {
          ...channel,
          members: newMembers,
        };
      });
    },
    [updateChannelById]
  );

  return {
    channels: data,
    getChannelById,
    getChannelsByIds,
    updateChannelById,
    updateChannels,
    updateChannelAndMessage,
    updateChannelMessages,
    addMessageToChannel,
    updateMessage,
    removeMessage,
    addChannelToList,
    updateChannelMemberLastReadId,
    resetData,
    markMessageAsRead,
  } as const;
};
