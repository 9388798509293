import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash-es';

/**
 * This hook is an extension of the React.useEffect hook
 * that performs a deep comparison on each value passed to the dependancy array.
 *
 * Read more about this hook: https://github.com/kentcdodds/use-deep-compare-effect
 */
function useDeepCompareMemoize(value) {
  const ref = useRef();
  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}

export default function useDeepCompareEffect(callback, dependencies) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, useDeepCompareMemoize(dependencies));
}
