import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getSessionCompanyId } from '../../../selectors/session';
import { FilterApplicationSourceToQueryParam } from '../ChannelList/helpers';
import { QueryChannelsParams } from '../client/types';
import { MessageCreateEvent } from '../client/ws-event';
import { getCandidateMemberFromChannel } from '../helpers';
import useGetChannelListParams from './useGetChannelListParams';

// This type ensure we won't miss new filters when we add new filters in the future
// hasUnread is not included because new messages are always unread
type CustomizableChatFilter = Omit<
  QueryChannelsParams,
  'lastChannelUpdatedAt' | 'page' | 'pageSize' | 'hasUnread'
> & {
  companyId: string; // Required because an employer can have multiple companies
};

const _checkMessageBelongsToCurrentFilter = (
  message: MessageCreateEvent['opData'],
  filters: CustomizableChatFilter
): Record<keyof CustomizableChatFilter, boolean> => {
  const {
    channelInfo: {
      company: { id: companyId },
      job: { id: jobId },
      members,
      applicationStatus,
      source,
    },
  } = message;

  const candidateMember = getCandidateMemberFromChannel({ members });
  if (!candidateMember) {
    throw new Error(
      'Candidate member not found in channel NEW MESSAGE websocket event'
    );
  }

  const candidateFullName = `${candidateMember.firstName} ${candidateMember.lastName}`;

  // Return type is Record<keyof CustomizableChatFilter, boolean> to ensure we won't miss new filters
  return {
    companyId: filters.companyId === companyId,
    jobID: filters.jobID ? filters.jobID === jobId : true,
    query: filters.query
      ? candidateFullName.toLowerCase().includes(filters.query.toLowerCase())
      : true,
    sources: filters.sources ? filters.sources.includes(source) : true,
    applicationStatus: filters.applicationStatus
      ? filters.applicationStatus === applicationStatus
      : true,
  };
};

export default function useCheckMessageBelongsToCurrentFilter() {
  const sessionCompanyId = useSelector(getSessionCompanyId);
  const { params } = useGetChannelListParams();

  const checkMessageBelongsToCurrentFilter = useCallback(
    (message: MessageCreateEvent['opData']) => {
      const checkResult = _checkMessageBelongsToCurrentFilter(message, {
        companyId: sessionCompanyId,
        jobID: params.jobId,
        query: params.keyword,
        sources: FilterApplicationSourceToQueryParam[params.appSource] || null,
        applicationStatus: params.appStatus,
      });

      return Object.values(checkResult).every(Boolean);
    },
    [params, sessionCompanyId]
  );

  return {
    checkMessageBelongsToCurrentFilter,
  } as const;
}
