import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { ReactQueryKeys } from '../../../common/react-query-keys';
import { useActiveChannelState } from '../ChannelList/hooks/useActiveChannelState';
import { GlintsChatWSEvent, WSEventOperation } from '../client/ws-event';
import { useGlintsChatClientContext } from '../provider/GlintsChatClientProvider';
import useGetChannelListParams from './useGetChannelListParams';

export default function useReconnectedEventListener() {
  const client = useGlintsChatClientContext();
  const queryClient = useQueryClient();
  const { activeChannel } = useActiveChannelState();
  const { queryKey: channelListQueryKey } = useGetChannelListParams();

  useEffect(() => {
    const handleEvent = (event: GlintsChatWSEvent) => {
      if (event.operation !== WSEventOperation.RECONNECTED) return;

      queryClient.invalidateQueries({
        queryKey: ReactQueryKeys.applicationDetailsShortKey,
      });

      queryClient.invalidateQueries({
        queryKey: ReactQueryKeys.messageListShort,
      });

      queryClient.invalidateQueries({
        queryKey: channelListQueryKey,
      });
    };

    client.on(WSEventOperation.RECONNECTED, handleEvent);

    return () => {
      client.off(WSEventOperation.RECONNECTED, handleEvent);
    };
  }, [activeChannel, channelListQueryKey, client, queryClient]);
}
