import { useCallback } from 'react';
import { isEmpty, omit } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';

import { updateUserCompanies } from '../../actions/user/companies';
import { getUserCompanies } from '../../selectors/user';
import { Company } from '../interfaces/entities';

type CallbackProps = {
  currentCompanyJobSlotsSubscription?: Company['currentCompanyJobSlotsSubscription'];
  sessionCompanyId: string | undefined;
};

/**
 * This hook is used to update the current session company data in the [userCompanies] with the latest job post mode data.
 */
export const useHandleUpdateUserCompanyJobPostModeState = () => {
  const dispatch = useDispatch();
  const userCompanies = useSelector(getUserCompanies);

  const handleUpdateUserCompanyJobPostModeState = useCallback(
    ({
      currentCompanyJobSlotsSubscription,
      sessionCompanyId,
    }: CallbackProps) => {
      if (
        !sessionCompanyId ||
        isEmpty(userCompanies) ||
        !currentCompanyJobSlotsSubscription
      ) {
        return;
      }
      const modifiedUserCompanies = userCompanies.map((company: Company) => {
        if (company.id !== sessionCompanyId) {
          return company;
        }

        return {
          ...company,
          currentCompanyJobSlotsSubscription: currentCompanyJobSlotsSubscription
            ? omit(currentCompanyJobSlotsSubscription, '__typename')
            : company.currentCompanyJobSlotsSubscription,
        };
      });

      dispatch(updateUserCompanies(modifiedUserCompanies));
    },
    [dispatch, userCompanies]
  );

  return {
    handleUpdateUserCompanyJobPostModeState,
  } as const;
};
