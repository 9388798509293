import { ApplicationSource } from '../../common/constants';

export enum ChannelQueryParam {
  CHANNEL_ID = 'cid',
  JOB_ID = 'job_id',
  POPUP_CHANNEL_ID = 'pop_cid',
  SOURCE = 'source',
  APP_STATUS = 'app_status',
  KEYWORD = 'keyword',
  SORT_BY_UNREAD = 'sort_by_unread',
  APP_SOURCE = 'app_source',
}

export enum MessagingUIMode {
  FULL = 'Chat Full Page',
  POPUP = 'Chat Widget',
}

export const FilterApplicationSource = {
  ALL_CANDIDATES: 'ALL_CANDIDATES',
  APPLICATIONS: 'APPLICATIONS',
  [ApplicationSource.TALENT_SEARCH_WEB]: ApplicationSource.TALENT_SEARCH_WEB,
  [ApplicationSource.RECOMMENDED_TALENT]: ApplicationSource.RECOMMENDED_TALENT,
} as const;

export type FilterApplicationSource =
  (typeof FilterApplicationSource)[keyof typeof FilterApplicationSource];

export const EMPLOYER_INITIATED_APPLICATION_SOURCES = [
  ApplicationSource.TALENT_SEARCH_WEB,
  ApplicationSource.RECOMMENDED_TALENT,
];
