import { difference, orderBy } from 'lodash-es';

import { ApplicationSource } from '../../../common/constants';
import { FilterApplicationSource } from '../constants';
import { GlintsChatChannel } from '../types/channel';
import {
  GlintsChatMessage,
  GlintsChatMessageDeliveryStatus,
} from '../types/message';

type MergeMessageListParams = {
  oldMessageList: GlintsChatMessage[];
  newMessageList: GlintsChatMessage[];
  removePendingMessages?: boolean;
};

export const mergeMessageList = ({
  oldMessageList,
  newMessageList,
  removePendingMessages = false,
}: MergeMessageListParams) => {
  const messageMap = oldMessageList.reduce(
    (acc, message) => ({ ...acc, [message.id]: message }),
    {} as Record<string, GlintsChatMessage>
  );

  newMessageList.forEach(message => {
    messageMap[message.id] = message;
  });

  let messageList = Object.values(messageMap);

  // when channel is refetched, remove pending messages to avoid duplication
  if (removePendingMessages) {
    messageList = messageList.filter(
      message =>
        message.deliveryStatus !== GlintsChatMessageDeliveryStatus.SENDING
    );
  }

  return orderBy<GlintsChatMessage>(
    messageList,
    message => message.createdAt.valueOf(),
    'desc'
  );
};

/**
 * Merge the old channel with the new channel.
 *
 * Omit the old channel data due to V2 omitting some fields if they become null.
 * Merge the message list of the old channel with the new channel.
 */
export const mergeChannel = (
  oldChannel: GlintsChatChannel,
  newChannel: GlintsChatChannel,
  removePendingMessages = true
): GlintsChatChannel => ({
  ...newChannel,
  messageList: mergeMessageList({
    oldMessageList: oldChannel.messageList,
    newMessageList: newChannel.messageList,
    removePendingMessages,
  }),
});

export const FilterApplicationSourceToQueryParam: Record<
  FilterApplicationSource,
  ApplicationSource[] | undefined
> = {
  [FilterApplicationSource.ALL_CANDIDATES]: undefined,
  [FilterApplicationSource.APPLICATIONS]: difference<ApplicationSource>(
    Object.values(ApplicationSource),
    [ApplicationSource.RECOMMENDED_TALENT, ApplicationSource.TALENT_SEARCH_WEB]
  ),
  [FilterApplicationSource.RECOMMENDED_TALENT]: [
    ApplicationSource.RECOMMENDED_TALENT,
  ],
  [FilterApplicationSource.TALENT_SEARCH_WEB]: [
    ApplicationSource.TALENT_SEARCH_WEB,
  ],
};
