import { useSelector } from 'react-redux';

import { UnleashFeatureNames } from '../../modules/Unleash/featureNames';
import { getIsFeatureEnabled } from '../../selectors/features';
import { countryCodeIsIndonesia } from '../helpers/countryCodeHelper';
import { useGetSessionCompany } from './useGetSessionCompany';

function useIsTierVerificationEnabled() {
  const isTierVerificationEnabled = useSelector(state =>
    getIsFeatureEnabled(state, UnleashFeatureNames.empTierVerificationEnabled)
  );
  const { sessionCompany } = useGetSessionCompany();

  return (
    isTierVerificationEnabled &&
    sessionCompany &&
    countryCodeIsIndonesia(sessionCompany.CountryCode)
  );
}

export default useIsTierVerificationEnabled;
