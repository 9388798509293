import { CompanyStatus } from '../constants';
import useGetCompanyById from './useGetCompanyById';
import useIsTierVerificationEnabled from './useIsTierVerificationEnabled';

export default function useGetCompanyVerificationInfo() {
  const { company } = useGetCompanyById();
  const isTierVerificationEnabled = useIsTierVerificationEnabled();
  const verificationInfo = company?.verificationInfo;
  const isUnverified = company && company.status === CompanyStatus.UNVERIFIED;

  const canShowInitiateVerification = Boolean(
    verificationInfo && !verificationInfo.isInitiated && isUnverified
  );

  return {
    company,
    verificationInfo: verificationInfo,
    canShowVerificationBanner:
      canShowInitiateVerification && isTierVerificationEnabled,
    dashboardNotification: {
      canShowVerifyCompanyNotification: canShowInitiateVerification,
      canShowCompanyVerificationInProgressNotification:
        isUnverified && verificationInfo?.isInitiated,
    },
  };
}
