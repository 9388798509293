import { useEffect } from 'react';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';

import { updateChannelsUnreadCountCache } from '../../../common/hooks/requests/useGetChannelsUnreadCount';
import { useChannelListState } from '../ChannelList/hooks/useChannelListState';
import { GlintsChatWSEvent, WSEventOperation } from '../client/ws-event';
import { transformChannelResponseToData } from '../helpers';
import { useGlintsChatClientContext } from '../provider/GlintsChatClientProvider';
import { GlintsChatChannel } from '../types/channel';
import useCheckMessageBelongsToCurrentFilter from './useCheckMessageBelongsToCurrentFilter';
import useGetChannelListParams from './useGetChannelListParams';

export default function useMessageCreateEventListener() {
  const client = useGlintsChatClientContext();
  const { updateChannels } = useChannelListState();
  const queryClient = useQueryClient();
  const { queryKey } = useGetChannelListParams();
  const { checkMessageBelongsToCurrentFilter } =
    useCheckMessageBelongsToCurrentFilter();

  useEffect(() => {
    const handleEvent = (event: GlintsChatWSEvent) => {
      if (event.operation !== WSEventOperation.NEW_MESSAGE) return;

      const { channelInfo, message } = event.opData;

      const channel = transformChannelResponseToData({
        ...channelInfo,
        messageList: [message],
      });

      // Update channel cache
      updateChannels([channel], false);

      // Update channel unread count cache
      updateChannelsUnreadCountCache(
        queryClient,
        channelInfo.id,
        channelInfo.unreadNumber
      );

      queryClient.setQueryData<
        InfiniteData<
          {
            channelList: GlintsChatChannel[];
            hasMore: boolean;
          },
          number
        >
      >(queryKey, data => {
        if (!data) return data;

        const allChannels = data.pages.flatMap(page => page.channelList);
        const existingChannelIndex = allChannels.findIndex(
          c => c.id === channel.id
        );

        let updatedFirstPage: {
          channelList: GlintsChatChannel[];
          hasMore: boolean;
        };

        // If channel is not in the cache, add it
        if (existingChannelIndex === -1) {
          // If channel does not belong to the current filter, do not add it
          if (!checkMessageBelongsToCurrentFilter(event.opData)) {
            return data;
          }

          updatedFirstPage = {
            ...data.pages[0],
            channelList: [channel, ...data.pages[0].channelList],
          };
        }

        // If channel is in the cache, move it to the top
        updatedFirstPage = {
          ...data.pages[0],
          channelList: [
            channel,
            ...data.pages[0].channelList.filter(c => c.id !== channel.id),
          ],
        };

        return {
          pages: [updatedFirstPage, ...data.pages.slice(1)],
          pageParams: data.pageParams,
        };
      });
    };

    client.on(WSEventOperation.NEW_MESSAGE, handleEvent);

    return () => {
      client.off(WSEventOperation.NEW_MESSAGE, handleEvent);
    };
  }, [
    checkMessageBelongsToCurrentFilter,
    client,
    queryClient,
    queryKey,
    updateChannels,
  ]);
}
