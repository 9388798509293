import { useEffect } from 'react';

import { useChannelListState } from '../ChannelList/hooks/useChannelListState';
import { GlintsChatWSEvent, WSEventOperation } from '../client/ws-event';
import { useGlintsChatClientContext } from '../provider/GlintsChatClientProvider';

export default function useMessageReadEventListener() {
  const client = useGlintsChatClientContext();
  const { updateChannelMemberLastReadId } = useChannelListState();

  useEffect(() => {
    const handleEvent = (event: GlintsChatWSEvent) => {
      if (event.operation !== WSEventOperation.READ_MESSAGE) return;

      const { messageID, channelID, userID } = event.opData;

      updateChannelMemberLastReadId(channelID, messageID, userID);
    };

    client.on(WSEventOperation.READ_MESSAGE, handleEvent);

    return () => {
      client.off(WSEventOperation.READ_MESSAGE, handleEvent);
    };
  }, [client, updateChannelMemberLastReadId]);
}
