import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getSessionCompanyId } from '../../selectors/session';
import { getUserCompanies } from '../../selectors/user';
import { CompanyWithMonetisationMode } from '../interfaces/entities';

type CompanyWithJobSlotsSubscription = Pick<
  CompanyWithMonetisationMode,
  | 'id'
  | 'currentCompanyJobSlotsSubscription'
  | 'status'
  | 'monetisationMode'
  | 'CountryCode'
  | 'LocationId'
  | 'legalDocument'
  | 'name'
  | 'fullProfileAccessStatus'
>;

export const useGetSessionCompany = () => {
  const sessionCompanyId: string | undefined = useSelector(getSessionCompanyId);
  const userCompanies: Array<CompanyWithJobSlotsSubscription> =
    useSelector(getUserCompanies);

  // we get the session company from the user companies as the state on session/company is not updated every time the page loads (updated when user visits dashboard again, but will not get updated for other pages)
  // the user companies are updated on every page load, so it contains the latest data every time
  const sessionCompany = useMemo(() => {
    if (!sessionCompanyId) return undefined;
    return userCompanies.find(company => company.id === sessionCompanyId);
  }, [sessionCompanyId, userCompanies]);

  return {
    sessionCompany,
    currentCompanyJobSlotsSubscription:
      sessionCompany?.currentCompanyJobSlotsSubscription,
    hasCurrentCompanyJobSlotsSubscription: Boolean(
      sessionCompany?.currentCompanyJobSlotsSubscription
    ),
  };
};
