import { apm } from '@elastic/apm-rum';
import { isS3Url, parseS3Url } from 'amazon-s3-url';
import dayjs from 'dayjs';

import { getOSSAssetURL } from '../../common/asset-manager';
import { USER_ROLES } from '../../common/constants';
import assertNever from '../../common/helpers/assertNever';
import { getConfig } from '../../config';
import { GlintsChatChannelWithNullableMessageList } from './client/types';
import { GlintsChatChannel } from './types/channel';
import {
  GlintsChatMessage,
  GlintsChatMessageContentActionCard,
  GlintsChatMessageContentActionCardStatus,
} from './types/message';

export const getCandidateMemberFromChannel = (
  channel: Pick<GlintsChatChannel, 'members'>
) => channel.members.find(member => member.loginRole === USER_ROLES.CANDIDATE);

export const getMemberById = (
  members: GlintsChatChannel['members'],
  id: GlintsChatChannel['members'][number]['id']
) => members.find(member => member.id === id);

export const getChatUserFullName = (member?: {
  firstName: string | null;
  lastName: string | null;
  email?: string;
}) => {
  if (!member) return '';

  const { firstName, lastName, email } = member;

  if (!firstName && !lastName) {
    const splitValues = email?.split('@');
    const emailName = splitValues?.[0];
    if (emailName) return emailName.trim();
  }
  if (!firstName && lastName) return lastName.trim();
  if (!lastName && firstName) return firstName.trim();

  return `${firstName} ${lastName}`;
};

export const getLatestMessageFromChannel = (
  channel: Pick<GlintsChatChannel, 'messageList'>
) => (channel.messageList.length > 0 ? channel.messageList[0] : null);

export const isMessageAlreadyRead = (
  channel: GlintsChatChannel,
  messageId: string,
  currentUserId: string
) => {
  const nonCurrentUserMessages = channel.messageList.filter(
    message => message.sender.id !== currentUserId
  );

  // The index of the message will be the unread number, for example
  // if the unread message is in the index 1,
  // the unread number after operation will be 1, the index 0 message
  const unreadMessageIndex = nonCurrentUserMessages.findIndex(
    message => message.id === messageId
  );

  return [
    unreadMessageIndex >= channel.unreadNumber,
    unreadMessageIndex,
  ] as const;
};

export const getActionCardMessageActingUserIdByStatus = (
  actionCardMessageContent: Pick<
    GlintsChatMessageContentActionCard['content'],
    'actionStatus' | 'requestUserID' | 'respondentUserID'
  >
) => {
  switch (actionCardMessageContent.actionStatus) {
    case GlintsChatMessageContentActionCardStatus.PENDING:
      return actionCardMessageContent.requestUserID;
    case GlintsChatMessageContentActionCardStatus.ACCEPTED:
    case GlintsChatMessageContentActionCardStatus.REJECTED:
    case GlintsChatMessageContentActionCardStatus.CANCELLED:
      return actionCardMessageContent.respondentUserID;
    default: {
      assertNever(actionCardMessageContent.actionStatus);
    }
  }
};

/**
 * Extracts the S3 URL from a Thumbor URL.
 * @param url The Thumbor URL.
 *
 * The Thumbor URL has a structure like `/<unsafe>/<dimensions>/<s3-url-path>`.
 * This function removes the first two segments (`unsafe` and dimensions) to reconstruct the original S3 URL.
 * For example, a Thumbor URL `images-dev.glints.com/unsafe/40x40/glints-dashboard-dev.s3.amazonaws.com/profile-picture-default/14.jpg`
 * would be converted to `https://glints-dashboard-dev.s3.amazonaws.com/profile-picture-default/14.jpg`.
 */
const extractS3UrlFromThumborUrl = (url: string) => {
  try {
    const parsedUrl = new URL(url);
    const pathSegments = parsedUrl.pathname
      .split('/')
      .filter(segment => segment);

    if (pathSegments.length < 3) {
      throw new Error('URL does not have enough path segments');
    }

    return `https://${pathSegments.slice(2).join('/')}`;
  } catch (error) {
    apm.captureError(error as Error);
    console.error('Invalid URL:', error);
    return '';
  }
};

const isOSSURL = (url: string) => {
  const { OSS_BUCKET_NAME, OSS_BUCKET_LOCATION } = getConfig();

  return url.includes(`${OSS_BUCKET_NAME}.${OSS_BUCKET_LOCATION}.aliyuncs.com`);
};

const getKeyFromOSSURL = (url: string) => {
  try {
    const parsedUrl = new URL(url);
    const path = parsedUrl.pathname.substring(1); // Remove the leading '/'
    const query = parsedUrl.search; // Get the query string

    return `${path}${query}`;
  } catch (error) {
    apm.captureError(error as Error);
    console.error('Invalid URL:', error);
    return '';
  }
};

export const convertS3ToOSSURL = (url: unknown) => {
  if (!url || typeof url !== 'string') {
    return undefined;
  }

  const thumborDomains = ['images.glints.com', 'images-dev.glints.com'];

  const isThumborUrl = thumborDomains.some(domain => url.includes(domain));

  const assetUrl = isThumborUrl ? extractS3UrlFromThumborUrl(url) : url;

  if (isOSSURL(assetUrl)) {
    // Replace direct OSS URL with the custom domain OSS URL
    return getOSSAssetURL(getKeyFromOSSURL(assetUrl));
  }

  if (!isS3Url(assetUrl)) {
    return assetUrl;
  }

  const { key } = parseS3Url(assetUrl);

  return getOSSAssetURL(key);
};

export const transformMessageResponseToData = (
  message: GlintsChatMessage
): GlintsChatMessage => ({
  ...message,
  createdAt: dayjs(message.createdAt).local(),
  updatedAt: dayjs(message.updatedAt).local(),
});

export function transformChannelResponseToData(
  channel: GlintsChatChannelWithNullableMessageList
): GlintsChatChannel {
  return {
    ...channel,
    createdAt: dayjs(channel.createdAt).local(),
    updatedAt: dayjs(channel.updatedAt).local(),
    talent: {
      ...channel.talent,
      careerStartDate: channel.talent.careerStartDate
        ? dayjs(channel.talent.careerStartDate).local()
        : null,
    },
    members: channel.members.map(member => ({
      ...member,
      lastSeen: member.lastSeen ? dayjs(member.lastSeen).local() : null,
    })),
    messageList: channel.messageList?.map(transformMessageResponseToData) || [],
  };
}

export const jumpToMessage = (
  messageId: string,
  behavior?: 'auto' | 'smooth'
) => {
  const messageCard = document.querySelector(
    `[data-message-id="${messageId}"]`
  );

  if (!messageCard) {
    apm.captureError(
      new Error(
        'Chat[v2] - Unable to scroll to the message card in the chat message list'
      )
    );
    return;
  }
  messageCard.scrollIntoView({ behavior: behavior ?? 'smooth' });
};
