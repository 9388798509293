import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function createGlobalState<T>(
  queryKey: unknown,
  initialData: T | null = null
) {
  return function () {
    const queryClient = useQueryClient();

    const { data } = useQuery({
      queryKey: [queryKey],
      // The data will be read from the cache first,
      // if it's not available, it will call the queryFn
      queryFn: () => Promise.resolve(initialData),
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
    });

    const setData = useCallback(
      (
        param:
          | Partial<T>
          | ((previousData: T | null) => Partial<T> | null | undefined)
      ) => {
        if (typeof param === 'function') {
          queryClient.setQueryData([queryKey], param);
          return;
        }

        queryClient.setQueryData([queryKey], param);
      },
      [queryClient]
    );

    function resetData() {
      queryClient.invalidateQueries({
        queryKey: [queryKey],
      });
      queryClient.refetchQueries({
        queryKey: [queryKey],
      });
    }

    return { data, setData, resetData };
  };
}
