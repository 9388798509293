import { QueryClient, QueryKey, useSuspenseQuery } from '@tanstack/react-query';

import { ReactQueryKeys } from '../../react-query-keys';
import { useAxiosWithAPI } from '../useAxiosWithAPI';

type Params = {
  channelIds: string[];
};

type Response = {
  data: {
    id: string;
    count: number;
  }[];
};

const isChannelsUnreadCountQueryKey = (
  queryKey: QueryKey
): queryKey is ReturnType<typeof ReactQueryKeys.channelsUnreadCount> =>
  queryKey[0] === 'channelsUnreadCount';

export const updateChannelsUnreadCountCache = (
  queryClient: QueryClient,
  channelId: string,
  count: number
) => {
  queryClient.setQueriesData<Response>(
    {
      predicate(query) {
        return Boolean(
          isChannelsUnreadCountQueryKey(query.queryKey) &&
            query.queryKey?.[1][channelId]
        );
      },
    },
    oldData => {
      if (!oldData) {
        return;
      }

      const newData = oldData.data.map(channel => {
        if (channel.id === channelId) {
          return {
            ...channel,
            count,
          };
        }

        return channel;
      });

      return {
        ...oldData,
        data: newData,
      };
    }
  );
};

export default function useGetChannelsUnreadCount({ channelIds }: Params) {
  const axios = useAxiosWithAPI();

  const { data } = useSuspenseQuery({
    queryKey: ReactQueryKeys.channelsUnreadCount(channelIds),
    queryFn: async () => {
      if (!channelIds.length) {
        return {
          data: [],
        };
      }

      const channelIdsParam = new URLSearchParams();

      channelIds.forEach(channelId => {
        channelIdsParam.append('channelIDs', channelId);
      });

      const response = await axios.get<Response>(
        `/glints-chat-v2/channels/unread?${channelIdsParam.toString()}`
      );

      return response.data;
    },
    retry: 1,
  });

  const transformDataToRecord = (data: Response['data']) =>
    data.reduce(
      (channelUnreadObject, { id, count }) => {
        channelUnreadObject[id] = count;
        return channelUnreadObject;
      },
      {} as Record<string, number>
    );

  return {
    channelsUnreadCount: transformDataToRecord(data.data || []),
  };
}
