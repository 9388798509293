import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { ReactQueryKeys } from '../../../common/react-query-keys';
import { useChannelListState } from '../ChannelList/hooks/useChannelListState';
import { GlintsChatWSEvent, WSEventOperation } from '../client/ws-event';
import { transformChannelResponseToData } from '../helpers';
import { useGlintsChatClientContext } from '../provider/GlintsChatClientProvider';

export default function useChannelUpdateEventListener() {
  const client = useGlintsChatClientContext();
  const queryClient = useQueryClient();
  const { updateChannelById } = useChannelListState();

  useEffect(() => {
    const handleEvent = (event: GlintsChatWSEvent) => {
      if (event.operation !== WSEventOperation.UPDATE_CHANNEL) return;

      const { opData: updatedChannel } = event;

      updateChannelById(
        updatedChannel.id,
        channel => {
          const isStatusChanged =
            channel.applicationStatus !== updatedChannel.applicationStatus;

          // For RT/CV Finder Application, if they reply to emp chat, it changes the
          // application status to IN_REVIEW, we need to refetch the application details
          // to access the hidden information, such as Email and Whatsapp Number
          if (isStatusChanged) {
            queryClient.invalidateQueries({
              queryKey: ReactQueryKeys.applicationDetails(channel.id),
            });
          }

          return transformChannelResponseToData({
            ...updatedChannel,
            messageList: channel.messageList,
          });
        },
        'replace'
      );
    };

    client.on(WSEventOperation.UPDATE_CHANNEL, handleEvent);

    return () => {
      client.off(WSEventOperation.UPDATE_CHANNEL, handleEvent);
    };
  }, [client, queryClient, updateChannelById]);
}
