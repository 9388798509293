import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  getSessionCompanyId,
  getSessionToken,
} from '../../../selectors/session';
import { GlintsChatClient } from '../client/GlintsChatClient';

const DELAY_BEFORE_DISCONNECT_IN_MS = 5000;

/**
 * GlintsChatWebsocketVisibilityHandler - A hook that automatically disconnects the websocket connection when the page is hidden and reconnects when the page is visible.
 */
export default function GlintsChatWebsocketVisibilityHandler() {
  const token = useSelector(getSessionToken);
  const sessionCompanyId = useSelector(getSessionCompanyId);

  useEffect(() => {
    const client = GlintsChatClient.getActiveInstance();
    if (!client) return;

    // eslint-disable-next-line jsx-control-statements/jsx-jcs-no-undef
    let timeOutId: NodeJS.Timeout;

    const handleVisibilityChange = () => {
      if (!token) return;

      if (document.visibilityState === 'hidden') {
        timeOutId = setTimeout(() => {
          client.disconnect();
        }, DELAY_BEFORE_DISCONNECT_IN_MS);
      } else {
        clearTimeout(timeOutId);

        if (!client.isWSConnected()) {
          client.connect();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [token, sessionCompanyId]);
}
