import React from 'react';
import {
  ApolloClient,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
  split,
  useApolloClient,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { RestLink } from 'apollo-link-rest';
import { SentryLink } from 'apollo-link-sentry';
import { OperationDefinitionNode } from 'graphql';
import { useSelector } from 'react-redux';

import { CompanyStatus } from './common/constants';
import {
  handleFraudCompanyError,
  httpForbiddenErrorCode,
} from './common/handleFraudCompany';
import { createAcceptLanguageHeader } from './common/helpers/createAcceptLanguageHeader';
import { getConfig } from './config';
import { typePolicies } from './graphql/cacheTypePolicies';
import { ApiV2ClientName } from './graphql/constants';
import { fragmentRegistry } from './graphql/fragmentRegistry';
import { getSessionToken } from './selectors/session';

export const graphQLClient = (token: string) => {
  const getUrl = (isWebsocket = false, apiBase?: string) => {
    const companyUrlObj = new URL(
      `${apiBase || config.API_BASE}/graphql`,
      `${window.origin}`
    );

    if (isWebsocket) {
      companyUrlObj.protocol =
        companyUrlObj.protocol === 'https:' ? 'wss' : 'ws';
    }

    return companyUrlObj;
  };
  const config = getConfig();
  const additionalHeaders = {
    Authorization: `Bearer ${token}`,
    'accept-language': createAcceptLanguageHeader(config.LANG),
  };
  const wsLink = new WebSocketLink({
    uri: getUrl(true).href,
    options: {
      lazy: true,
      reconnect: true,
      connectionParams: async () => ({
        authorization: additionalHeaders.Authorization,
      }),
    },
  });

  const httpLinkV1 = new HttpLink({
    uri: getUrl().href,
    fetch,
    credentials: 'include',
  });

  const httpLinkV2 = new HttpLink({
    uri: getUrl(false, config.API_V2_BASE).href,
    fetch,
    credentials: 'include',
  });

  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      ...additionalHeaders,
    },
  }));

  const sentryLink = new SentryLink({
    attachBreadcrumbs: {
      includeQuery: true,
      includeVariables: true,
      includeError: true,
      includeFetchResult: true,
    },
  });

  const restLink = new RestLink({
    uri: config.API_BASE,
    responseTransformer: async response =>
      response.json().then(({ data }: { data: unknown }) => data),
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    const fraudError = (graphQLErrors || []).find(
      er => er.extensions?.companyStatus
    );
    if (fraudError) {
      handleFraudCompanyError(
        httpForbiddenErrorCode,
        fraudError.extensions?.companyStatus as
          | CompanyStatus.BLOCKED
          | CompanyStatus.UNDER_INVESTIGATION
      );
      return;
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  });

  const terminatingLink = split(
    operation => operation.getContext().clientName === ApiV2ClientName,
    httpLinkV2,
    httpLinkV1
  );
  const link = from([sentryLink, authLink, errorLink, restLink]).split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(
        query
      ) as OperationDefinitionNode;
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    terminatingLink
  );

  return new ApolloClient({
    link,
    cache: new InMemoryCache({
      typePolicies,
      fragments: fragmentRegistry,
    }),
  });
};

export const useTypedApolloClient = () =>
  useApolloClient() as ReturnType<typeof graphQLClient>;

export type ApolloClientInstanceType = ReturnType<typeof useTypedApolloClient>;

export const ApolloConfig = ({ children }: { children: React.ReactNode }) => {
  const token = useSelector(getSessionToken);
  const client = graphQLClient(token);
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
