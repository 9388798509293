import { apm } from '@elastic/apm-rum';
import WebSocket from 'isomorphic-ws';

import { USER_ROLES } from '../../../common/constants';
import {
  DEFAULT_INIT_WS_RETRY_INTERVAL_IN_MS,
  DEFAULT_MAX_WS_RETRY_COUNT,
  DEFAULT_OFFLINE_WS_RETRY_INTERVAL_IN_MS,
  WS_HAPI_PATH,
} from './consts';
import type { GlintsChatClient } from './GlintsChatClient';
import { GlintsChatUserRoles } from './types';
import {
  addConnectionEventListeners,
  removeConnectionEventListeners,
  retryInterval,
  sleep,
} from './utils';
import {
  GlintsChatWSEvent,
  isGlintsChatWSEvent,
  WSEventOperation,
} from './ws-event';

// Type guards to check WebSocket error type
const isCloseEvent = (
  res: WebSocket.CloseEvent | WebSocket.Data | WebSocket.ErrorEvent
): res is WebSocket.CloseEvent =>
  (res as WebSocket.CloseEvent).code !== undefined;

const isErrorEvent = (
  res: WebSocket.CloseEvent | WebSocket.Data | WebSocket.ErrorEvent
): res is WebSocket.ErrorEvent =>
  (res as WebSocket.ErrorEvent).error !== undefined;

/**
 * Forked from https://github.com/GetStream/stream-chat-js/blob/master/src/connection.ts
 * with some modifications
 */
export class GlintsChatWSConnection {
  // global from constructor
  client: GlintsChatClient;

  // local vars
  consecutiveFailures: number;
  isConnecting: boolean;
  isHealthy: boolean;
  isResolved?: boolean;
  isManuallyClosed?: boolean;
  isConnectionLimitReached?: boolean;
  connectionOpen?: Promise<GlintsChatWSEvent>;
  resolvePromise?: (value: GlintsChatWSEvent) => void;
  rejectPromise?: (reason?: Error) => void;
  ws?: WebSocket;
  wsID: number;

  constructor(client: GlintsChatClient) {
    /** GlintsChatClient */
    this.client = client;
    /** consecutive failures influence the duration of the timeout */
    this.consecutiveFailures = 0;
    /** We only make 1 attempt to reconnect at the same time.. */
    this.isConnecting = false;
    /** Boolean that indicates if the connection promise is resolved */
    this.isHealthy = false;
    /** Incremented when a new WS connection is made */
    this.wsID = 1;

    addConnectionEventListeners(this.onlineStatusChanged);
  }

  async connect() {
    if (this.isConnecting) {
      throw Error(
        `You've called connect twice, can only attempt 1 connection at the time`
      );
    }

    try {
      await this._connect();

      this.consecutiveFailures = 0;
    } catch (error) {
      this.isHealthy = false;
      this.consecutiveFailures += 1;

      this.reconnect();

      throw error;
    }
  }

  private _buildUrl() {
    const userRole = this.client.getUserRole();
    const mappedUserRole =
      userRole === USER_ROLES.CANDIDATE
        ? GlintsChatUserRoles.CANDIDATE
        : GlintsChatUserRoles.EMPLOYER;
    const params = new URLSearchParams({
      token: this.client.getToken(),
      'x-user-role': mappedUserRole,
    });

    const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';

    return `${protocol}://${window.location.hostname}:${
      window.location.port
    }${WS_HAPI_PATH}?${params.toString()}`;
  }

  private async _connect() {
    if (this.isConnecting) return; // simply ignore _connect if it's currently trying to connect

    this.isManuallyClosed = false;
    this.isConnecting = true;

    try {
      this._setupConnectionPromise();
      const wsURL = this._buildUrl();
      this.ws = new WebSocket(wsURL);
      this.ws.onclose = this.onclose.bind(this, this.wsID);
      this.ws.onerror = this.onerror.bind(this, this.wsID);
      this.ws.onmessage = this.onmessage.bind(this, this.wsID);
      const response = await this.connectionOpen;
      this.isConnecting = false;

      return response;
    } catch (error) {
      this.isConnecting = false;
      throw error;
    }
  }

  async reconnect(interval?: number) {
    // only allow 1 connection at the time
    if (this.isConnecting || this.isHealthy) {
      return;
    }

    // If it reach the max retry, destroy the connection and return
    if (this.consecutiveFailures > DEFAULT_MAX_WS_RETRY_COUNT) {
      this.destroyCurrentWSConnection();
      return;
    }

    // reconnect in case of on error or on close
    if (interval === undefined) {
      interval = retryInterval(this.consecutiveFailures);
    }

    // reconnect, or try again after a little while...
    await sleep(interval);

    // Check once again if by some other call to _reconnect is active or connection is
    // already restored, then no need to proceed.
    if (this.isConnecting || this.isHealthy) {
      return;
    }

    this.destroyCurrentWSConnection();

    try {
      await this._connect();

      this.client.dispatchEvent({
        operation: WSEventOperation.RECONNECTED,
      });

      this.consecutiveFailures = 0;
    } catch (error) {
      this.isHealthy = false;
      this.consecutiveFailures += 1;

      // reconnect on WS failures
      this.reconnect();
    }
  }

  onlineStatusChanged(event: Event) {
    if (event.type === 'offline') {
      // mark the connection as down
      this._setHealth(false);
    } else if (event.type === 'online') {
      if (!this.isHealthy) {
        this.reconnect(DEFAULT_INIT_WS_RETRY_INTERVAL_IN_MS);
      }
    }
  }

  private setIsConnectionLimitReached = (newValue: boolean) => {
    this.isConnectionLimitReached = newValue;

    this.client.dispatchEvent({
      operation: WSEventOperation.CONNECTION_LIMIT_REACHED,
      opData: {
        reached: newValue,
      },
    });
  };

  onmessage = (wsID: number, event: WebSocket.MessageEvent) => {
    if (this.wsID !== wsID) return;

    const data = typeof event.data === 'string' ? JSON.parse(event.data) : null;

    // we wait till the first message before we consider the connection open..
    // the reason for this is that auth errors and similar errors trigger a ws.onopen and immediately
    // after that a ws.onclose..
    if (
      !this.isResolved &&
      isGlintsChatWSEvent(data) &&
      data.operation === WSEventOperation.CONNECTION_ESTABLISHED
    ) {
      this.isResolved = true;

      this.resolvePromise?.(data);
      this._setHealth(true);
      this.setIsConnectionLimitReached(false);
    }

    // trigger the event..
    this.client.handleEvent(event);
  };

  onclose = async (wsID: number, event: WebSocket.CloseEvent) => {
    const createError = () =>
      new Error(
        `LOG: WS closed with code ${event.code} and reason - ${
          event.reason
        }, event object: ${JSON.stringify(event)}`
      );

    console.error(createError().message);
    apm.captureError(createError());

    if (this.wsID !== wsID) return;

    this._setHealth(false);
    this.isConnecting = false;

    if (this.isManuallyClosed) {
      return;
    }

    // 1001 is expected code when server closes the connection due to concurent connection limit
    // In this case, we should not try to reconnect and inform the user instead
    if (event.code === 1001) {
      this.setIsConnectionLimitReached(true);
    } else {
      await this.reconnect(0);
    }
  };

  onerror = (wsID: number, event: WebSocket.ErrorEvent) => {
    if (this.wsID !== wsID) return;

    this._setHealth(false);
    this.isConnecting = false;

    this.rejectPromise?.(this._errorFromWSEvent(event));
  };

  /**
   * _setHealth - Sets the connection to healthy or unhealthy.
   * Broadcasts an event in case the connection status changed.
   *
   * @param {boolean} healthy boolean indicating if the connection is healthy or not
   *
   */
  private _setHealth = (healthy: boolean) => {
    if (healthy === this.isHealthy) return;

    this.isHealthy = healthy;

    if (this.isHealthy) {
      this.client.dispatchEvent({
        operation: 'CONNECTION_CHANGE',
        opData: {
          connected: this.isHealthy,
        },
      });
      return;
    }

    // we're offline, wait few seconds and fire and event if still offline
    setTimeout(() => {
      if (this.isHealthy) return;
      this.client.dispatchEvent({
        operation: 'CONNECTION_CHANGE',
        opData: {
          connected: this.isHealthy,
        },
      });
    }, DEFAULT_OFFLINE_WS_RETRY_INTERVAL_IN_MS);
  };

  /**
   * _destroyCurrentWSConnection - Removes the current WS connection
   *
   */
  destroyCurrentWSConnection() {
    if (!this.isHealthy) return;

    // increment the ID, meaning we will ignore all messages from the old
    // ws connection from now on.
    this.wsID += 1;

    try {
      this?.ws?.removeAllListeners();
      this?.ws?.close();
    } catch (e) {
      // we don't care
    }
  }

  /**
   * _setupPromise - sets up the this.connectOpen promise
   */
  _setupConnectionPromise = () => {
    this.isResolved = false;
    /** a promise that is resolved once ws.open is called */
    this.connectionOpen = new Promise<GlintsChatWSEvent>((resolve, reject) => {
      this.resolvePromise = resolve;
      this.rejectPromise = reject;
    });
  };

  /**
   * disconnect - Disconnect the connection and doesn't recover...
   *
   */
  disconnect() {
    this.wsID += 1;
    this.isConnecting = false;

    removeConnectionEventListeners(this.onlineStatusChanged);

    this.isHealthy = false;
    this.isManuallyClosed = true;

    // remove ws handlers...
    if (this.ws && this.ws.removeAllListeners) {
      this.ws.removeAllListeners();
    }

    // and finally close...
    // Assigning to local here because we will remove it from this before the
    // promise resolves.
    const { ws } = this;
    if (ws && ws.close && ws.readyState === ws.OPEN) {
      ws.close(
        1000,
        'Manually closed connection by calling client.disconnect()'
      );
    }

    delete this.ws;
  }

  _errorFromWSEvent = (
    event: WebSocket.CloseEvent | WebSocket.Data | WebSocket.ErrorEvent,
    isWSFailure = true
  ) => {
    let code;
    let statusCode;
    let message;
    if (isCloseEvent(event)) {
      code = event.code;
      statusCode = 'unknown';
      message = event.reason;
    }

    if (isErrorEvent(event)) {
      code = event.error.code;
      statusCode = event.error.StatusCode;
      message = event.error.message;
    }

    const error = new Error(
      `WS failed with code ${code} and reason - ${message}`
    ) as Error & {
      code?: string | number;
      isWSFailure?: boolean;
      StatusCode?: string | number;
    };
    error.code = code;
    /**
     * StatusCode does not exist on any event types but has been left
     * as is to preserve JS functionality during the TS implementation
     */
    error.StatusCode = statusCode;
    error.isWSFailure = isWSFailure;
    return error;
  };
}
