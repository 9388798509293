import { useRef, useState } from 'react';

import useDeepCompareEffect from './useDeepCompareEffect';

/**
 * This hook takes in a config object required for the IntersectionObserverAPI.
 * It returns a setNode function to set the node that we want to observe,
 * and an entry object which describes the intersection change for the observed node.
 *
 * Read more about IntersectionObserver API: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 * Read more about this hook: https://medium.com/the-non-traditional-developer/how-to-use-an-intersectionobserver-in-a-react-hook-9fb061ac6cb5
 */
export default function useIntersectionObserver({
  root = null,
  rootMargin = '0px 0px 0px 0px',
  threshold = 0,
}) {
  const [entry, updateEntry] = useState({});
  const [node, setNode] = useState(null);
  const observer = useRef(null);

  useDeepCompareEffect(() => {
    // Disconnect the observer every time useEffect is called
    // to prevent multiple nodes from being observed and multiple entries from being returned.
    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new window.IntersectionObserver(
      // The callback takes in an array of entries.
      // We assume that we are only observing one node at a time so we destructure the first entry.
      ([entry]) => updateEntry(entry),
      {
        root,
        rootMargin,
        threshold,
      }
    );

    const { current: currentObserver } = observer;
    if (node) {
      currentObserver.observe(node);
    }

    return () => currentObserver.disconnect();
  }, [node, root, rootMargin, threshold]);

  return [setNode, entry];
}
