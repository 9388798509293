import { useEffect } from 'react';

import { useChannelListState } from '../ChannelList/hooks/useChannelListState';
import { GlintsChatWSEvent, WSEventOperation } from '../client/ws-event';
import { transformMessageResponseToData } from '../helpers';
import { useGlintsChatClientContext } from '../provider/GlintsChatClientProvider';

export default function useMessageUpdateEventListener() {
  const client = useGlintsChatClientContext();

  const { updateMessage } = useChannelListState();

  useEffect(() => {
    const handleEvent = (event: GlintsChatWSEvent) => {
      if (event.operation !== WSEventOperation.UPDATE_MESSAGE) return;

      const { channelID, message } = event.opData;

      updateMessage(
        channelID,
        message.id,
        transformMessageResponseToData(message),
        'replace'
      );
    };

    client.on(WSEventOperation.UPDATE_MESSAGE, handleEvent);
    return () => {
      client.off(WSEventOperation.UPDATE_MESSAGE, handleEvent);
    };
  }, [client, updateMessage]);
}
