import React from 'react';
import { PlainButton, Typography } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useMount } from 'react-use';
import styled from 'styled-components';

import { useGetDivElementHeight } from '../../../common/hooks/useGetDivElementHeight';
import { useVIPMembershipExpiredLocalStorage } from '../../../common/hooks/useVIPMembershipExpiredLocalStorage';
import { VIPMembershipCompanyProductInventory } from '../../../modules/CreditSystem/Features/FeaturesAndPurchasesTabs/PurchasedProducts/types';
import { useGlintsVIPFreeTrialOrPurchaseModal } from '../../../modules/CreditSystem/PurchaseModals/GlintsVIPPurchaseModal/hooks/useGlintsVIPFreeTrialOrPurchaseModal';
import { useGetSubscriptionFreeTrialOfferTimePeriodText } from '../../../modules/CreditSystem/VIPSubscriptionFreeTrialOfferBanner/useGetSubscriptionFreeTrialOfferTimePeriodText';
import { UnleashFeatureNames } from '../../../modules/Unleash/featureNames';
import { getIsFeatureEnabled } from '../../../selectors/features';
import RenderVIPCTALabelBasedOnVIPFreeTrial from '../../VIPFreeTrial/RenderVIPCTALabelBasedOnVIPFreeTrial';
import { StickyContainer, StyledBanner } from '../styles.sc';
import {
  trackVIPExpiredNavbarBannerCTAClicked,
  trackVIPExpiredNavbarBannerImpression,
} from './tracking';

const StyledButton = styled(PlainButton)`
  display: inline;
  padding: 0;
`;

type Props = {
  setFixedBannerHeight: (height: number) => void;
  closeBanner: () => void;
  isExpiredMembershipForTrial: boolean;
} & Pick<
  VIPMembershipCompanyProductInventory,
  'activeSubscriptionFreeTrialOffer' | 'activeSubscriptionInformation'
>;

const messages = defineMessages({
  trialExpired: {
    id: 'text-your-vip-membership-trial-has',
    defaultMessage:
      'Your VIP membership trial has expired. <link>Upgrade to VIP</link> to use VIP benefits.',
  },
  vipExpired: {
    id: 'interactive-vip-membership-expired',
    defaultMessage:
      'Your VIP membership has expired. <link>Upgrade to VIP</link> to use VIP benefits.',
  },
  vipExpiredAutoRenewal: {
    id: 'text-your-vip-is-expired-auto-renewal',
    defaultMessage:
      'Your VIP is expired. <link>Enable auto-renewal</link> and get {timePeriod} free!',
  },
  enableAutoRenewal: {
    id: 'text-enable-auto-renewal-link',
    defaultMessage: '<link>Enable auto-renewal</link>',
  },
});
export const VIPMembershipExpiredBanner = ({
  setFixedBannerHeight,
  closeBanner,
  isExpiredMembershipForTrial,
  activeSubscriptionFreeTrialOffer,
  activeSubscriptionInformation,
}: Props) => {
  const { ref } = useGetDivElementHeight({
    setElementHeight: setFixedBannerHeight,
  });

  const { openGlintsVIPPurchaseModal, isModalChunkLoadPending } =
    useGlintsVIPFreeTrialOrPurchaseModal();
  const isSubscriptionAutoRenewalEnabled = useSelector(state =>
    getIsFeatureEnabled(
      state,
      UnleashFeatureNames.empEnableVIPSubscriptionPackages
    )
  );

  const { setVIPMembershipExpiredFirstSeen } =
    useVIPMembershipExpiredLocalStorage();
  function getText() {
    if (isSubscriptionAutoRenewalEnabled && !activeSubscriptionInformation) {
      return activeSubscriptionFreeTrialOffer
        ? messages.vipExpiredAutoRenewal
        : messages.enableAutoRenewal;
    }
    return isExpiredMembershipForTrial
      ? messages.trialExpired
      : messages.vipExpired;
  }

  const timePeriodUnit = useGetSubscriptionFreeTrialOfferTimePeriodText({
    activeSubscriptionFreeTrialOffer,
  });

  useMount(() => {
    setVIPMembershipExpiredFirstSeen();
    trackVIPExpiredNavbarBannerImpression();
  });

  return (
    <StickyContainer ref={ref}>
      <StyledBanner
        type="fixed"
        status="warning"
        showIcon={false}
        onDismiss={() => {
          closeBanner();
          setFixedBannerHeight(0);
        }}
      >
        <Typography variant="body2" color={Neutral.B18} as="div">
          <FormattedMessage
            {...getText()}
            values={{
              link: (text: React.ReactNode) => (
                <StyledButton
                  onClick={() => {
                    trackVIPExpiredNavbarBannerCTAClicked();
                    return openGlintsVIPPurchaseModal();
                  }}
                  disabled={isModalChunkLoadPending}
                >
                  <Typography variant="body2">
                    <RenderVIPCTALabelBasedOnVIPFreeTrial>
                      {text}
                    </RenderVIPCTALabelBasedOnVIPFreeTrial>
                  </Typography>
                </StyledButton>
              ),
              timePeriod: `${
                activeSubscriptionFreeTrialOffer?.timePeriod ?? 0
              } ${timePeriodUnit}`,
            }}
          />
        </Typography>
      </StyledBanner>
    </StickyContainer>
  );
};
