import { ScreenSize } from 'glints-aries/es';
import { useMediaQuery } from 'react-responsive';

export const useIsMobileVersion = (componentName = '') => {
  const componentBreakpointMapping: Record<string, number> = {
    ChannelHeaderMenus: 576,
    TopNavCTA: ScreenSize.tablet,
    ResponseRate: ScreenSize.tablet,
  };

  const maxWidth =
    componentBreakpointMapping[componentName] ?? ScreenSize.desktopM;

  const isTablet = useMediaQuery({
    maxWidth: maxWidth - 1,
  });

  return isTablet;
};
