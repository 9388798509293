import { useCallback } from 'react';
import {
  useQuery,
  useSuspenseQuery,
  WatchQueryFetchPolicy,
} from '@apollo/client';
import { useSelector } from 'react-redux';

import { BaseEmployerCommerceInput } from '../../modules/EmployerCommerce/types';
import { UnleashFeatureNames } from '../../modules/Unleash/featureNames';
import { getIsFeatureEnabled } from '../../selectors/features';
import { getSessionCompanyId } from '../../selectors/session';
import { CurrentCompanyJobSlotsSubscription } from '../interfaces/entities';
import { getCurrentCompanyJobSlotsSubscriptionQuery } from './graphql';
import { useUpdateUserCompanyJobPostModeState } from './useUpdateUserCompanyJobPostModeState';

type QueryVariables = {
  input: BaseEmployerCommerceInput;
  withCSVDownload: boolean;
};
type QueryResponse = {
  getCurrentCompanyJobSlotsSubscription: CurrentCompanyJobSlotsSubscription;
};

type UseGetCurrentCompanyJobSlotsSubscription = {
  cacheTypePolicy?: WatchQueryFetchPolicy;
};

export const useGetCurrentCompanyJobSlotsSubscription = (
  { cacheTypePolicy }: UseGetCurrentCompanyJobSlotsSubscription = {
    cacheTypePolicy: undefined,
  }
) => {
  const sessionCompanyId: string = useSelector(getSessionCompanyId);
  const isCSVDownloadEnabled = useSelector(state =>
    getIsFeatureEnabled(state, UnleashFeatureNames.empCSVDownloadEnabled)
  );
  const { data, loading, error, refetch } = useQuery<
    QueryResponse,
    QueryVariables
  >(getCurrentCompanyJobSlotsSubscriptionQuery, {
    variables: {
      input: {
        companyId: sessionCompanyId,
      },
      withCSVDownload: isCSVDownloadEnabled,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: cacheTypePolicy || 'network-only',
  });

  useUpdateUserCompanyJobPostModeState({
    currentCompanyJobSlotsSubscription:
      data?.getCurrentCompanyJobSlotsSubscription,
    sessionCompanyId,
  });

  const refetchGetCurrentCompanyJobSlotsSubscription = useCallback(() => {
    refetch();
  }, [refetch]);

  return {
    currentCompanyJobSlotsSubscriptionData:
      data?.getCurrentCompanyJobSlotsSubscription,
    loading,
    error,
    refetchGetCurrentCompanyJobSlotsSubscription,
  } as const;
};

export const useGetCurrentCompanyJobSlotsSubscriptionSuspenseQuery = (
  queryKey?: string
) => {
  const sessionCompanyId: string = useSelector(getSessionCompanyId);
  const isCSVDownloadEnabled = useSelector(state =>
    getIsFeatureEnabled(state, UnleashFeatureNames.empCSVDownloadEnabled)
  );
  const { data } = useSuspenseQuery<QueryResponse, QueryVariables>(
    getCurrentCompanyJobSlotsSubscriptionQuery,
    {
      variables: {
        input: {
          companyId: sessionCompanyId,
        },
        withCSVDownload: isCSVDownloadEnabled,
      },
      fetchPolicy: 'cache-and-network',
      queryKey: queryKey || 'getCurrentCompanyJobSlotsSubscription',
    }
  );

  useUpdateUserCompanyJobPostModeState({
    currentCompanyJobSlotsSubscription:
      data?.getCurrentCompanyJobSlotsSubscription,
    sessionCompanyId,
  });

  return {
    currentCompanyJobSlotsSubscriptionData:
      data.getCurrentCompanyJobSlotsSubscription,
  } as const;
};
