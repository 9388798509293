import { useGlintsVIPMembershipInfo } from '../../components/GlintsVIPMembershipInfo/hooks/useGlintsVIPMembershipInfo';
import { countryCodeIsIndonesia } from '../helpers/countryCodeHelper';
import { useGetSessionCompany } from './useGetSessionCompany';

type Params = {
  skipVIPMembershipCheck?: boolean;
};

type FeatureVariant = 'enabled' | 'disabled' | 'ignored';

export default function useIsProfileLimitAccessEnabled(
  params?: Params
): boolean {
  const { sessionCompany } = useGetSessionCompany();
  const { vipMembershipInfo } = useGlintsVIPMembershipInfo();
  const variant = sessionCompany?.fullProfileAccessStatus?.variant
    ?.value as FeatureVariant;

  const vipMembershipCheck = params?.skipVIPMembershipCheck
    ? true
    : !vipMembershipInfo.isActive;

  return Boolean(
    variant === 'enabled' &&
      sessionCompany &&
      countryCodeIsIndonesia(sessionCompany.CountryCode) &&
      vipMembershipCheck
  );
}
