import React, { Component } from 'react';
import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  initializeSession,
  logout,
  setSessionToken,
  updateMarketingMetadata,
} from '../actions/session';
import { persistMe } from '../actions/user/me';
import {
  MARKETING_PARAMS_NAMESPACE,
  MARKETING_PARAMS_WHITELIST_PATTERNS,
} from '../common/constants';
import { withApolloClient } from '../common/withApolloClient';
import { getLocation } from '../selectors/location';
import {
  getScopedTokenActionCompleted,
  getSessionToken,
  isSessionInitialized,
} from '../selectors/session';
import { getUserId, getUserMetadata } from '../selectors/user';
import LoadingFullscreen from './LoadingFullscreen';

class SessionInitializer extends Component {
  static propTypes = {
    routes: PropTypes.array,

    scopedTokenActionCompleted: PropTypes.bool,
    sessionInitialized: PropTypes.bool.isRequired,
    userExists: PropTypes.bool,
    allowScopedToken: PropTypes.bool,

    initializeSession: PropTypes.func,
    logout: PropTypes.func,
    persistMe: PropTypes.func,
    setSessionToken: PropTypes.func,
    updateMarketingMetadata: PropTypes.func,

    children: PropTypes.node,

    location: PropTypes.object,
    marketingMetadata: PropTypes.object,
    userMetadata: PropTypes.object,

    sessionToken: PropTypes.string,
    token: PropTypes.string,
    apolloClient: PropTypes.object,
  };

  async componentDidMount() {
    await this.props.initializeSession(this.props.apolloClient);

    const locationQueryKeys = this.props.location
      ? Object.keys(this.props.location.query)
      : [];
    const marketingKeys = locationQueryKeys.filter(key =>
      MARKETING_PARAMS_WHITELIST_PATTERNS.some(pattern => {
        const regexPattern = RegExp(pattern);
        return regexPattern.test(key);
      })
    );

    const marketingMeta = {};
    marketingKeys.forEach(
      key => (marketingMeta[key] = this.props.location.query[key])
    );

    if (Object.keys(marketingKeys).length > 0) {
      this.props.updateMarketingMetadata(marketingMeta);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.userExists &&
      prevProps.userExists !== this.props.userExists
    ) {
      this.props.persistMe({
        metadata: {
          [MARKETING_PARAMS_NAMESPACE]: {
            ...this.props.userMetadata,
            ...this.props.marketingMetadata,
          },
        },
      });
    }

    if (
      this.props.scopedTokenActionCompleted &&
      prevProps.scopedTokenActionCompleted !==
        this.props.scopedTokenActionCompleted
    ) {
      const token = this.props.sessionToken;
      const isScoped = Boolean(jwtDecode(token).scope);
      if (isScoped) {
        setTimeout(() => this.props.logout(), 3000);
      }
    }
  }

  render() {
    return (
      <Choose>
        <When condition={this.props.sessionInitialized}>
          {this.props.children}
        </When>
        <Otherwise>
          <LoadingFullscreen />
        </Otherwise>
      </Choose>
    );
  }
}

SessionInitializer = connect(
  state => ({
    marketingMetadata: state.session.marketingMetadata,
    userExists: Boolean(getUserId(state)),
    userMetadata: getUserMetadata(state),
    scopedTokenActionCompleted: getScopedTokenActionCompleted(state),
    sessionInitialized: isSessionInitialized(state),
    sessionToken: getSessionToken(state),
    location: getLocation(state),
  }),
  dispatch => ({
    initializeSession: apolloClient =>
      dispatch(initializeSession(apolloClient)),
    logout: () => dispatch(logout()),
    persistMe: values => dispatch(persistMe(values)),
    setSessionToken: token => dispatch(setSessionToken(token)),
    updateMarketingMetadata: metadata =>
      dispatch(updateMarketingMetadata(metadata)),
  })
)(SessionInitializer);

export default withApolloClient(SessionInitializer);
