import React, { Suspense } from 'react';
import { apm } from '@elastic/apm-rum';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router';
import { useEffectOnce } from 'react-use';

import { useJobSuspense } from '../../common/hooks/requests/useJob';
import { useGetSessionCompany } from '../../common/hooks/useGetSessionCompany';
import { JobResponse } from '../../common/interfaces/responses';
import ContainerV2 from '../../components/ContainerV2';
import ErrorBoundaryWithCrashReporting from '../../components/Error/ErrorBoundaryWithCrashReporting';
import { getSessionCompanyCountryCode } from '../../selectors/session';
import { makeRequiredFormDefaultValues } from './common/constants';
import useConvertJobData from './hooks/useConvertJobData';
import useDraft from './hooks/useDraft';

const JobCopyRedirectionComponent = () => {
  const { jobId } = useParams<{
    jobId: string;
  }>();
  const { sessionCompany } = useGetSessionCompany();
  const { job } = useJobSuspense({ jobId });
  const isJobFromCurrentCompany = job?.company?.id === sessionCompany?.id;

  const { createDraft } = useDraft();
  const countryCode = useSelector(getSessionCompanyCountryCode);
  const { convertJobFormStateToPayload, convertResponseToFormState } =
    useConvertJobData();

  const history = useHistory();

  useEffectOnce(() => {
    const createDraftAndRedirect = async () => {
      if (!isJobFromCurrentCompany) {
        history.replace('/403');
        return;
      }

      try {
        const response = await createDraft(
          convertJobFormStateToPayload(
            makeRequiredFormDefaultValues(countryCode, {
              ...convertResponseToFormState({ data: job } as JobResponse, {
                isCopy: true,
              }),
            })
          )
        );
        history.replace(`/job/draft/${response.data.id}`);
      } catch (error) {
        apm.captureError(error as Error);
        history.replace('/500');
      }
    };
    createDraftAndRedirect();
  });

  return <ContainerV2 isLoading={true} />;
};

export default function JobCopyRedirection() {
  return (
    <ErrorBoundaryWithCrashReporting fallback={<Redirect to="/500" />}>
      <Suspense fallback={<ContainerV2 isLoading={true} />}>
        <JobCopyRedirectionComponent />
      </Suspense>
    </ErrorBoundaryWithCrashReporting>
  );
}
