import { useIntl } from 'react-intl';

import { CompanyProductSubscriptionFreeTrialOffer } from '../../../common/interfaces/company-product-subscription';
import { timePeriodUnitToText } from '../constants';

export function useGetSubscriptionFreeTrialOfferTimePeriodText({
  activeSubscriptionFreeTrialOffer,
}: {
  activeSubscriptionFreeTrialOffer: Pick<
    CompanyProductSubscriptionFreeTrialOffer,
    'timePeriod' | 'timePeriodUnit'
  > | null;
}) {
  const { formatMessage } = useIntl();
  if (
    !activeSubscriptionFreeTrialOffer ||
    !activeSubscriptionFreeTrialOffer.timePeriod ||
    !activeSubscriptionFreeTrialOffer.timePeriodUnit
  ) {
    return null;
  }
  return formatMessage(
    timePeriodUnitToText[activeSubscriptionFreeTrialOffer.timePeriodUnit],
    { count: activeSubscriptionFreeTrialOffer.timePeriod }
  );
}
