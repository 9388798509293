import { BooleanParam, StringParam, useQueryParams } from 'use-query-params';

import { ReactQueryKeys } from '../../../common/react-query-keys';
import { ChannelQueryParam } from '../constants';
import {
  FilterApplicationSourceQueryParam,
  FilterApplicationStatusQueryParam,
} from '../queryParams';

export default function useGetChannelListParams() {
  const [{ job_id, keyword, sort_by_unread, app_source, app_status }] =
    useQueryParams({
      [ChannelQueryParam.JOB_ID]: StringParam,
      [ChannelQueryParam.KEYWORD]: StringParam,
      [ChannelQueryParam.SORT_BY_UNREAD]: BooleanParam,
      [ChannelQueryParam.APP_SOURCE]: FilterApplicationSourceQueryParam,
      [ChannelQueryParam.APP_STATUS]: FilterApplicationStatusQueryParam,
    });

  const params = {
    jobId: job_id || undefined,
    keyword: keyword || undefined,
    sortByUnread: Boolean(sort_by_unread),
    appSource: app_source,
    appStatus: app_status,
  };

  return {
    queryKey: ReactQueryKeys.channelList(params),
    params,
  };
}
