import React from 'react';
import { Flex } from 'glints-aries/es';
import { Link, OutlineButton, Typography } from 'glints-aries/es/@next';
import { defineMessages, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { useGetDivElementHeight } from '../../../common/hooks/useGetDivElementHeight';
import { CompanyProductSubscription } from '../../../common/interfaces/company-product-subscription';
import { PaymentService } from '../../../common/interfaces/payment-service';
import NodesJoin from '../../NodesJoin';
import { StickyContainer, StyledBanner } from '../styles.sc';

type Props = {
  setFixedBannerHeight: (height: number) => void;
  subscription: Pick<
    CompanyProductSubscription,
    'creditPurchaseOrderSubscriptionDetail' | 'paymentService'
  >;
};

const messages = defineMessages({
  paymentFailedMobilePlatform: {
    id: 'text-our-last-attempted-charge-mobile',
    defaultMessage:
      'Our last attempted charge was unsuccessful. Please update your details in the mobile app.',
  },
  paymentFailedWebPlatform: {
    id: 'text-our-last-attempted-charge-web',
    defaultMessage:
      'Our last attempted charge was unsuccessful. Please update your payment as soon as possible.',
  },
  paymentFailed: {
    id: 'text-payment-failed',
    defaultMessage: 'Payment failed:',
  },
  updatePayment: {
    id: 'interactive-update-payment',
    defaultMessage: 'Update Payment',
  },
});

const BannerWrapper = styled.div`
  > div {
    flex-direction: column;
  }
  > div > div {
    padding-right: 0;
    width: 100%;
  }

  .payment-link {
    text-decoration: none !important;
  }
`;
export default function VIPSubscriptionPaymentFailedBanner({
  setFixedBannerHeight,
  subscription,
}: Props) {
  const { ref } = useGetDivElementHeight({
    setElementHeight: setFixedBannerHeight,
  });

  const {
    creditPurchaseOrder: { paymentUrl },
  } = subscription.creditPurchaseOrderSubscriptionDetail || {
    creditPurchaseOrder: {},
  };
  const { paymentService } = subscription;

  return (
    <StickyContainer ref={ref}>
      <BannerWrapper>
        <StyledBanner
          type="fixed"
          status="warning"
          showIcon={false}
          dismissable={false}
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            style={{
              width: '100%',
            }}
          >
            <Typography variant="body1">
              <NodesJoin separator={' '}>
                <Typography variant="body2" as="span">
                  <FormattedMessage {...messages.paymentFailed} />
                </Typography>
                <FormattedMessage
                  {...(paymentService === PaymentService.XENDIT_SUBSCRIPTION
                    ? messages.paymentFailedWebPlatform
                    : messages.paymentFailedMobilePlatform)}
                  tagName="span"
                />
              </NodesJoin>
            </Typography>
            <If
              condition={
                paymentService === PaymentService.XENDIT_SUBSCRIPTION &&
                paymentUrl
              }
            >
              <Link
                href={paymentUrl}
                className="payment-link"
                referrerPolicy="no-referrer"
                target="_blank"
              >
                <OutlineButton>
                  <Typography variant="button">
                    <FormattedMessage {...messages.updatePayment} />
                  </Typography>
                </OutlineButton>
              </Link>
            </If>
          </Flex>
        </StyledBanner>
      </BannerWrapper>
    </StickyContainer>
  );
}
