import { includes } from 'lodash-es';

import {
  ApplicationSource,
  ApplicationStatus,
  USER_ROLES,
} from '../../../common/constants';
import { GlintsChatChannel } from '../types/channel';
import {
  GlintsChatMessage,
  GlintsChatMessageContentActionCard,
  GlintsChatMessageContentFile,
  GlintsChatMessageContentImage,
  GlintsChatMessageContentSystemNotification,
  GlintsChatMessageContentText,
  GlintsChatMessageContentType,
  UpdateMessageOperation,
} from '../types/message';

// The messageList field in GlintsChatChannel is nullable from the response
type NullableMessageList = GlintsChatChannel['messageList'] | null;

export const GlintsChatUserRoles = {
  CANDIDATE: 'CANDIDATE',
  EMPLOYER: 'EMPLOYER',
} as const;

export type GlintsChatUserRoles =
  (typeof GlintsChatUserRoles)[keyof typeof GlintsChatUserRoles];
export type GlintsChatChannelWithNullableMessageList = Omit<
  GlintsChatChannel,
  'messageList'
> & {
  messageList?: NullableMessageList;
};

export type QueryChannelsParams = {
  lastChannelUpdatedAt?: number;
  page?: number;
  pageSize?: number;
  jobID?: string | null;
  query?: string | null;
  hasUnread?: boolean | null;
  sources?: ApplicationSource[] | null;
  applicationStatus?: ApplicationStatus | null;
};

export type QueryChannelResponse = {
  code: string;
  error?: string | null;
  data: GlintsChatChannelWithNullableMessageList;
};

export type QueryChannelsResponse = {
  code: string;
  error?: string | null;
  data: {
    hasMore: boolean;
    channelList: GlintsChatChannelWithNullableMessageList[];
  };
};

export type QueryMessagesParams = {
  channelId: string;
  lastMessageCreatedAt?: number;
  lastMessageID?: string;
  limit?: number;
};

export type QueryMessagesResponse = {
  code: string;
  error?: string | null;
  data: {
    hasMore: boolean;
    messageList: NullableMessageList;
    channelInfo: GlintsChatChannel;
  };
};

export type SendTextMessageResponse = {
  code: string;
  data: GlintsChatMessage;
};

export type MessageReadInput = {
  channelId: string;
  messageId: string;
};

export type MessageReadResponse = {
  code: string;
  error?: string | null;
};

export type SendableMessageContent =
  | GlintsChatMessageContentText
  | GlintsChatMessageContentImage
  | GlintsChatMessageContentFile
  | GlintsChatMessageContentActionCard
  | GlintsChatMessageContentSystemNotification;

export const isSendableMessageContent = (
  message: GlintsChatMessage
): message is GlintsChatMessage & SendableMessageContent =>
  includes(
    [
      GlintsChatMessageContentType.TEXT,
      GlintsChatMessageContentType.IMAGE,
      GlintsChatMessageContentType.FILE,
    ],
    message.contentType
  );

export type UpdateMessageParams = {
  channelID: GlintsChatChannel['id'];
  messageID: GlintsChatMessage['id'];
  operationType: UpdateMessageOperation;
  content?: Partial<GlintsChatMessage['content']>;
};

export type UpdateChannelMembersParams = {
  curMembers: {
    id: string;
    loginRole: USER_ROLES;
  }[];
};
