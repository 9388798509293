import {
  gql,
  skipToken,
  useApolloClient,
  useSuspenseQuery,
} from '@apollo/client';
import { useSelector } from 'react-redux';

import { getSessionCompanyId } from '../../selectors/session';
import { Company } from '../interfaces/entities';
const query = gql`
  query getCompanyById($id: String!) {
    getCompanyById(id: $id) {
      id
      status
      verificationInfo {
        ...companyVerificationInfoFragment
      }
    }
  }
`;

type QueryOutput = {
  getCompanyById: Pick<Company, 'id' | 'verificationInfo' | 'status'>;
};

type QueryInput = {
  id: string;
};

export async function preloadCompanyById(
  companyId: string,
  apolloClient: ReturnType<typeof useApolloClient>
) {
  await apolloClient.query<QueryOutput, QueryInput>({
    query,
    variables: { id: companyId },
  });
}

export default function useGetCompanyById() {
  const companyId: string | undefined = useSelector(getSessionCompanyId);
  const { data } = useSuspenseQuery<QueryOutput, QueryInput>(
    query,
    companyId
      ? {
          variables: { id: companyId },
        }
      : skipToken
  );

  return {
    company: data?.getCompanyById,
  } as const;
}
