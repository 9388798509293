import { PaymentService } from './payment-service';
import { TimePeriodUnit } from './time-period-unit';

export const NextPaymentStatus = {
  SCHEDULED: 'SCHEDULED',
  RETRYING: 'RETRYING',
  FAILED: 'FAILED',
} as const;

export type NextPaymentStatus =
  (typeof NextPaymentStatus)[keyof typeof NextPaymentStatus];

export const CompanyProductSubscriptionStatus = {
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  TERMINATED: 'TERMINATED',
  CANCELLED: 'CANCELLED',
} as const;

export type CompanyProductSubscriptionStatus =
  (typeof CompanyProductSubscriptionStatus)[keyof typeof CompanyProductSubscriptionStatus];

export type CompanyProductSubscription = {
  id: string;
  nextPaymentChargeDate: string | null;
  nextPaymentStatus: NextPaymentStatus;
  subscriptionStatus: CompanyProductSubscriptionStatus;
  paymentService: PaymentService;
  creditPurchaseOrderSubscriptionDetail: {
    creditPurchaseOrder: {
      totalPayment: number;
      paymentUrl: string;
    };
  } | null;
};

export type CompanyProductSubscriptionFreeTrialOffer = {
  id: string;
} & (
  | {
      timePeriod: number;
      timePeriodUnit: TimePeriodUnit;
    }
  | {
      timePeriod: null;
      timePeriodUnit: null;
    }
);
