import './common/dayjsConfig';

import React, { useCallback } from 'react';
import Favicon from 'react-favicon';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';

import Drawer from './components/Drawer';
import { Footer } from './components/Footer';
import LoadingModal from './components/LoadingModal';
import { MonetisationFixedInfoBanner } from './components/MonetisationFixedInfoBanner/MonetisationFixedInfoBanner';
import ActivateFreeTrialModalProvider from './components/VIPFreeTrialModal/ActivateFreeTrialModalProvider';
import { WhatsappButton } from './components/WhatsappButton/WhatsappButton';
import { MainHeaderHeightContext } from './context';
import GlintsMeta from './GlintsMeta';
import { CompanyReportStatusRedirect } from './modules/CompanyReportStatus/CompanyReportStatusRedirect';
import { GlintsVIPPurchaseModalProvider } from './modules/CreditSystem/PurchaseModals/GlintsVIPPurchaseModal/Providers/GlintsVIPPurchaseModalProvider';
import GlintsChatWebsocketVisibilityHandler from './modules/GlintsChat/components/GlintsChatWebsocketVisibilityHandler';
import WSEventListenerWrapper from './modules/GlintsChat/components/WSEventListenerWrapper';
import { GlintsChatClientProvider } from './modules/GlintsChat/provider/GlintsChatClientProvider';
import Modal from './modules/Modal';
import {
  isSidebarVisible,
  Navbar,
  Sidebar,
  toggleSidebar,
} from './modules/Navigation';
import AlternativeNavbar from './modules/Navigation/AlternativeNavbar/AlternativeNavbar';
import useShowAlternativeHeader from './modules/Navigation/AlternativeNavbar/useShowAlternativeHeader';
import { NavigationProvider } from './modules/Navigation/NavigationProvider/Context';
import { LogoutBroadcastListener } from './modules/Session/LogoutBroadcastListener';
import RenderDesktopOnly from './modules/UseMobileAppPrompt/RenderDesktopOnly';
import { ZendeskVisibilityProvider } from './modules/Zendesk/ZendeskVisibilityProvider';
import MainRoutes from './routes';
import { getShowFeatureLoadingModal } from './selectors/features';

export const App = () => {
  const [headerHeight, setHeaderHeight] = React.useState(0);
  const [fixedBannerHeight, setFixedBannerHeight] = React.useState(0);
  const sidebarOpen = useSelector(isSidebarVisible);
  const isFeatureLoading = useSelector(getShowFeatureLoadingModal);

  const dispatch = useDispatch();

  const handleDrawerOpenChange = useCallback(() => {
    dispatch(toggleSidebar());
  }, [dispatch]);

  const {
    alternativeHeaderContent,
    showAlternativeHeader,
    preventNavigation,
    setShowAlternativeHeader,
  } = useShowAlternativeHeader();

  return (
    <GlintsChatClientProvider>
      <WSEventListenerWrapper>
        <NavigationProvider
          value={{
            setShowAlternativeHeader,
          }}
        >
          <MainHeaderHeightContext.Provider
            value={headerHeight + fixedBannerHeight}
          >
            <GlintsChatWebsocketVisibilityHandler />
            <ZendeskVisibilityProvider>
              <ActivateFreeTrialModalProvider>
                <GlintsVIPPurchaseModalProvider>
                  <Favicon url="images/favicon.ico" />
                  <GlintsMeta title="Glints - Career Discovery & Development Platform" />
                  <Switch>
                    <Route path="*">
                      {isFeatureLoading && <LoadingModal />}
                      <RenderDesktopOnly>
                        <MonetisationFixedInfoBanner
                          setFixedBannerHeight={setFixedBannerHeight}
                        />
                      </RenderDesktopOnly>
                      <CompanyReportStatusRedirect />
                      <Choose>
                        <When condition={showAlternativeHeader}>
                          <AlternativeNavbar
                            setHeaderHeight={setHeaderHeight}
                            top={top}
                            canNavigate={!preventNavigation}
                          >
                            {alternativeHeaderContent}
                          </AlternativeNavbar>
                        </When>
                        <Otherwise>
                          <Navbar
                            setHeaderHeight={setHeaderHeight}
                            top={fixedBannerHeight}
                          />
                        </Otherwise>
                      </Choose>

                      <Drawer
                        sidebar={<Sidebar />}
                        position="right"
                        open={sidebarOpen}
                        onOpenChange={handleDrawerOpenChange}
                        headerHeight={headerHeight}
                        style={{ overflow: 'unset' }}
                      >
                        <div className="content">
                          <MainRoutes />
                        </div>
                        <Footer />
                        <Modal />
                        <LogoutBroadcastListener />
                      </Drawer>
                      <WhatsappButton />
                    </Route>
                  </Switch>
                </GlintsVIPPurchaseModalProvider>
              </ActivateFreeTrialModalProvider>
            </ZendeskVisibilityProvider>
          </MainHeaderHeightContext.Provider>
        </NavigationProvider>
      </WSEventListenerWrapper>
    </GlintsChatClientProvider>
  );
};
