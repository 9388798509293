import { useMemo } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useGetSessionCompany } from '../../../common/hooks/useGetSessionCompany';
import { ReactQueryKeys } from '../../../common/react-query-keys';
import { useChannelListState } from '../../GlintsChat/ChannelList/hooks/useChannelListState';
import { transformChannelResponseToData } from '../../GlintsChat/helpers';
import { useGlintsChatClientContext } from '../../GlintsChat/provider/GlintsChatClientProvider';

const DEFAULT_PAGE_SIZE = 1000;

/**
 This hook is used to get the approximate total unread count of all channels by fetching the first 1000 channels from a company and computing the total unread count. 

 * Note that the total unread count is approximate because we are only fetching the first 1000 channels. If a company has more than 1000 channels, the total unread count will be inaccurate.
 */
export default function useGetApproximateTotalUnreadCount() {
  const { sessionCompany } = useGetSessionCompany();
  const glintsChatClient = useGlintsChatClientContext();
  const { updateChannels, channels } = useChannelListState();

  useSuspenseQuery({
    queryKey: ReactQueryKeys.globalChannelList,
    queryFn: async () => {
      const channelsResponse = await glintsChatClient.queryChannels({
        pageSize: DEFAULT_PAGE_SIZE,
      });

      const channelList = channelsResponse.data.channelList || [];

      // Store the channels in the cache
      updateChannels(channelList.map(transformChannelResponseToData));

      return channelList;
    },
    refetchOnMount: false,
  });

  const totalGlobalUnreadCount = useMemo(() => {
    if (!channels) {
      return undefined;
    }

    return Object.values(channels)
      .filter(
        channel =>
          channel.company.id === sessionCompany?.id && channel.unreadNumber > 0
      )
      .reduce((totalUnread, channel) => totalUnread + channel.unreadNumber, 0);
  }, [channels, sessionCompany?.id]);

  return { totalGlobalUnreadCount } as const;
}
