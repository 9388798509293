export const ReactQueryKeys = {
  applicationDetailsShortKey: ['applicationDetails'],
  applicationDetails: (applicationId: string) => [
    'applicationDetails',
    applicationId,
  ],
  channelByApplicationId: (applicationId: string) => [
    'channelByApplicationId',
    applicationId,
  ],
  globalChannelList: ['glintsChatChannelsGlobal'],
  channelList: (params: Record<string, unknown>) => [
    'glintsChatChannels',
    params,
  ],
  messageList: (channelId: string) => ['glintsChatMessages', channelId],
  messageListShort: ['glintsChatMessages'],
  channelsUnreadCount: (channelIds: string[]) => {
    // Convert array to object so the key is not order dependent
    const channelIdsMap = channelIds.reduce(
      (acc, channelId) => {
        acc[channelId] = channelId;
        return acc;
      },
      {} as Record<string, string>
    );

    return ['channelsUnreadCount', channelIdsMap] as const;
  },
};
