import { SuspenseQueryHookFetchPolicy } from '@apollo/client';
import dayjs from 'dayjs';

import useIsVIPMembershipEnabled from '../../../common/hooks/useIsVIPMembershipEnabled';
import {
  CompanyProductSubscription,
  CompanyProductSubscriptionFreeTrialOffer,
} from '../../../common/interfaces/company-product-subscription';
import { EmployerPurchaseProductType } from '../../../common/interfaces/entities';
import { CompanyProductTrialInformationDetail } from '../../../common/interfaces/product-free-trial-offer';
import { VIPMembershipCompanyProductInventory } from '../../../modules/CreditSystem/Features/FeaturesAndPurchasesTabs/PurchasedProducts/types';
import {
  ActiveFreeCreditBenefit,
  CreditBenefit,
} from '../../../modules/CreditSystem/types';
import useGetProductBenefit from './useGetProductBenefit';
import { useGetVIPMembershipInfo } from './useGetVIPMembershipInfo';

export const GLINTS_VIP_MEMBERSHIP_EXPIRING_THRESHOLD_DAYS = 7;

type VIPMembershipInfo = {
  expiryDateTime?: string | null;
  expiryInDays: number | null;
  isExpiring: boolean;
  isExpired: boolean;
  isActive: boolean;
  isExpiredMembershipForTrial: boolean;
  isExpiringMembershipForTrial: boolean;
  // will be undefined if the query execution was skipped
  hasNoInventoryRecord: boolean | undefined;
  creditBenefit: CreditBenefit | null;
  activeFreeCreditBenefit: ActiveFreeCreditBenefit | null;
  trialInformationDetail: CompanyProductTrialInformationDetail;
  activeSubscriptionInformation: CompanyProductSubscription | null;
  activeSubscriptionFreeTrialOffer: CompanyProductSubscriptionFreeTrialOffer | null;
};

export const getVIPInfoFromInventory = (
  inventory: Pick<
    VIPMembershipCompanyProductInventory,
    | 'amount'
    | 'expiryDateTime'
    | 'product'
    | 'activeFreeCreditBenefit'
    | 'trialInformationDetail'
    | 'activeSubscriptionInformation'
    | 'activeSubscriptionFreeTrialOffer'
    | 'isActive'
  >
): VIPMembershipInfo => {
  const { expiryDateTime, isActive } = inventory;
  const trialInformationDetail = inventory.trialInformationDetail;
  const isExpired = !isActive;
  const isExpiring =
    !inventory.activeSubscriptionInformation &&
    Boolean(
      isActive &&
        expiryDateTime &&
        dayjs(expiryDateTime).diff(dayjs(), 'days') <
          GLINTS_VIP_MEMBERSHIP_EXPIRING_THRESHOLD_DAYS
    );
  const expiryInDays =
    !inventory.activeSubscriptionInformation && expiryDateTime
      ? dayjs(expiryDateTime).diff(dayjs(), 'days')
      : null;

  return {
    expiryDateTime,
    expiryInDays,
    isActive,
    isExpired,
    isExpiring,
    hasNoInventoryRecord: false,
    creditBenefit:
      'creditBenefit' in inventory.product
        ? inventory.product.creditBenefit
        : null,
    activeFreeCreditBenefit: inventory.activeFreeCreditBenefit || null,
    trialInformationDetail,
    isExpiredMembershipForTrial: Boolean(
      isExpired &&
        trialInformationDetail.endDate &&
        !trialInformationDetail.isExtended
    ),
    isExpiringMembershipForTrial: Boolean(
      isExpiring && trialInformationDetail.isActive
    ),
    activeSubscriptionInformation: inventory.activeSubscriptionInformation,
    activeSubscriptionFreeTrialOffer:
      inventory.activeSubscriptionFreeTrialOffer,
  } as const;
};

const useGetVIPProductBenefit = (hasInventoryRecord: boolean) => {
  const isVIPMembershipEnabled = useIsVIPMembershipEnabled();

  const { productCreditBenefit } = useGetProductBenefit({
    productType: EmployerPurchaseProductType.VIP_MEMBERSHIP,
    skip: !isVIPMembershipEnabled || hasInventoryRecord,
  });

  return { productCreditBenefit };
};

export const useGlintsVIPMembershipInfo = (
  fetchPolicy?: SuspenseQueryHookFetchPolicy,
  queryKey?: string
): {
  vipMembershipInfo: VIPMembershipInfo;
} => {
  const { data, skipped } = useGetVIPMembershipInfo(fetchPolicy, queryKey);
  const { productCreditBenefit } = useGetVIPProductBenefit(
    Boolean(data?.getVIPMembershipInfo)
  );

  if (!data?.getVIPMembershipInfo) {
    return {
      vipMembershipInfo: {
        expiryDateTime: null,
        expiryInDays: null,
        isActive: false,
        isExpired: false,
        isExpiring: false,
        hasNoInventoryRecord: skipped ? undefined : true,
        creditBenefit: productCreditBenefit || null,
        activeFreeCreditBenefit: null,
        isExpiredMembershipForTrial: false,
        isExpiringMembershipForTrial: false,
        trialInformationDetail: {
          isActive: false,
          endDate: null,
          startDate: null,
          isExtended: false,
        },
        activeSubscriptionInformation: null,
        activeSubscriptionFreeTrialOffer: null,
      },
    };
  }

  return {
    vipMembershipInfo: getVIPInfoFromInventory(data.getVIPMembershipInfo),
  } as const;
};
