import { TrackingService } from '../../../../../../common/tracking/trackingService';
import { MessagingUIMode } from '../../../../constants';

export type ChatPageViewedEventSource =
  | 'employer-ats-v2'
  | 'employer-ats-v2-modal'
  | 'application-details-page'
  | 'chat-widget'
  | 'navbar';

export const trackChatPageViewedEvent = (properties: {
  source: ChatPageViewedEventSource;
  uiType: MessagingUIMode;
}) => {
  const transformedProperties = {
    source: properties.source,
    ui_type: properties.uiType,
  };

  TrackingService.logEvent('Chat Page Viewed', transformedProperties);
};
