import React, { ReactNode, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, useAlert } from 'glints-aries/es/@next';
import { Blue, Neutral } from 'glints-aries/es/@next/utilities/colors';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import { initializeSession } from '../../../actions/session';
import { useTypedApolloClient } from '../../../apollo-client';
import { useGetAssetURL } from '../../../common/asset-manager';
import axios from '../../../common/axios-ts';
import { isError409 } from '../../../common/ErrorHelpers';
import { login } from '../../../modules/CompanyOnboarding/SignInPage/actions';
import {
  SignUpSource,
  trackSignUpSuccess,
} from '../../../modules/CompanyOnboarding/SignUpPage/actions';
import { useAppDispatch } from '../../../store';
import { PHONE_NUMBER_SEPARATOR } from '../../Form/PhoneNumberInput';
import SignUpButton from './components/SignUpButton/SignUpButton';
import SignupTextInput from './components/SignUpTextInput/SignUpTextInput';
import {
  defaultSignUpFormState,
  errorMessages,
  ID_CALLING_CODE,
  SignUpFormState,
  signUpValidationSchema,
} from './constants';
import { StyledFormContainer, StyledRow } from './HomePageSignUpForm.sc';
import { homePageSignUpFormMessages } from './homePageSignUpFormMessages';

const HomePageSignUpForm = () => {
  const { open } = useAlert();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { getAssetURL } = useGetAssetURL();

  const [loading, setLoading] = useState(false);

  const { formatMessage } = useIntl();

  const { handleSubmit, control, setError } = useForm<SignUpFormState>({
    defaultValues: defaultSignUpFormState,
    resolver: yupResolver(signUpValidationSchema(formatMessage)),
  });
  const apolloClient = useTypedApolloClient();

  const onSubmit = async (data: SignUpFormState) => {
    try {
      setLoading(true);

      const response = await axios(null).post('/users', {
        data: {
          ...data,
          phone: `+${ID_CALLING_CODE}${PHONE_NUMBER_SEPARATOR}${data.phone}`,
        },
      });

      trackSignUpSuccess(SignUpSource.HOMEPAGE, response.data.data.id);

      await dispatch(login(data.email, data.password) as any);
      await dispatch(initializeSession(apolloClient) as any);

      history.push('/email-verification');
    } catch (error) {
      if (isError409((error as any)?.response)) {
        setError('email', {
          message: formatMessage(errorMessages.emailAlreadyTaken),
        });
      } else {
        open({
          title: formatMessage(errorMessages.somethingWentWrong),
          status: 'error',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledFormContainer onSubmit={handleSubmit(onSubmit)}>
      <StyledRow>
        <Controller
          name="firstName"
          control={control}
          render={({
            formState: { errors },
            field: { onChange, onBlur, value },
          }) => (
            <SignupTextInput
              placeholder={formatMessage(homePageSignUpFormMessages.firstName)}
              errorText={errors.firstName?.message}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          render={({
            formState: { errors },
            field: { onChange, onBlur, value },
          }) => (
            <SignupTextInput
              placeholder={formatMessage(homePageSignUpFormMessages.lastName)}
              errorText={errors.lastName?.message}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({
            formState: { errors },
            field: { onChange, onBlur, value },
          }) => (
            <SignupTextInput
              placeholder={formatMessage(
                homePageSignUpFormMessages.workEmailAddress
              )}
              errorText={errors.email?.message}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />
        <Controller
          name="phone"
          control={control}
          render={({
            formState: { errors },
            field: { onChange, onBlur, value },
          }) => (
            <SignupTextInput
              prefix={
                <Typography
                  style={{ marginTop: 1 }}
                  variant="body1"
                  color={Neutral.B18}
                >
                  +{ID_CALLING_CODE}
                </Typography>
              }
              type="tel"
              placeholder={formatMessage(
                homePageSignUpFormMessages.phoneNumber
              )}
              errorText={errors.phone?.message}
              onBlur={onBlur}
              onChange={onChange}
              value={value || ''}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({
            formState: { errors },
            field: { onChange, onBlur, value },
          }) => (
            <SignupTextInput
              placeholder={formatMessage(homePageSignUpFormMessages.password)}
              type="password"
              errorText={errors.password?.message}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />
        <Controller
          name="confirmPassword"
          control={control}
          render={({
            formState: { errors },
            field: { onChange, onBlur, value },
          }) => (
            <SignupTextInput
              placeholder={formatMessage(
                homePageSignUpFormMessages.confirmPassword
              )}
              type="password"
              errorText={errors.confirmPassword?.message}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </StyledRow>
      <Typography variant="overline" style={{ display: 'block' }}>
        {formatMessage(homePageSignUpFormMessages.signUpAgreement, {
          link: (children: ReactNode) => (
            <a
              href={getAssetURL('GlintsTermsAndConditions.pdf', 'docs')}
              target="_blank"
              rel="noreferrer"
            >
              <Typography as="span" variant="overline" color={Blue.S99}>
                {children}
              </Typography>
            </a>
          ),
        })}
      </Typography>
      <SignUpButton loading={loading} />
    </StyledFormContainer>
  );
};
export default HomePageSignUpForm;
