import React, { ComponentType } from 'react';
import { useApolloClient } from '@apollo/client';

/**
 * A higher-order component that injects the Apollo Client instance as a prop.
 * This is useful for when you need to access the Apollo Client instance in a class component.
 * Note: this is a workaround for the lack of support for hooks in class components.
 */
export function withApolloClient<P = Record<string, unknown>>(
  WrappedComponent: ComponentType<P>
) {
  const Wrapper = (props: P & JSX.IntrinsicAttributes) => {
    const apolloClient = useApolloClient();

    return <WrappedComponent {...props} apolloClient={apolloClient} />;
  };

  return Wrapper;
}
