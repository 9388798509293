/* eslint-disable max-lines */
import { defineMessages, MessageDescriptor } from 'react-intl';
import type { RouteProps } from 'react-router';

import config from '../config';
import { GetAssetURLCallback, useGetAssetURL } from './asset-manager';

export const COUNTRY: SupportedCountry = config?.COUNTRY ?? 'default';

export type SupportedCountry = keyof typeof COUNTRY_DOMAINS;

export const COUNTRY_DOMAINS = {
  default: 'default',
  hk: 'hk',
  id: 'id',
  my: 'my',
  ph: 'ph',
  sg: 'sg',
  th: 'th',
  tw: 'tw',
  vn: 'vn',
};

export const COUNTRY_CALLING_CODES = {
  [COUNTRY_DOMAINS.my]: '+60', // Malaysia
  [COUNTRY_DOMAINS.id]: '+62', // Indonesia
  [COUNTRY_DOMAINS.ph]: '+63', // Philippines
  [COUNTRY_DOMAINS.sg]: '+65', // Singapore
  [COUNTRY_DOMAINS.th]: '+66', // Thailand
  [COUNTRY_DOMAINS.vn]: '+84', // Vietname
  [COUNTRY_DOMAINS.hk]: '+852', // Hong Kong
  [COUNTRY_DOMAINS.tw]: '+886', // Taiwan
} as const;

export const COUNTRY_CURRENCY = {
  [COUNTRY_DOMAINS.my]: 'MYR', // Malaysia
  [COUNTRY_DOMAINS.id]: 'IDR', // Indonesia
  [COUNTRY_DOMAINS.ph]: 'PHP', // Philippines
  [COUNTRY_DOMAINS.sg]: 'SGD', // Singapore
  [COUNTRY_DOMAINS.th]: 'THB', // Thailand
  [COUNTRY_DOMAINS.vn]: 'VND', // Vietnam
  [COUNTRY_DOMAINS.hk]: 'HKD', // Hong Kong
  [COUNTRY_DOMAINS.tw]: 'TWD', // Taiwan
  [COUNTRY_DOMAINS.default]: 'IDR',
} as const;

export type Currencies =
  (typeof COUNTRY_CURRENCY)[keyof typeof COUNTRY_CURRENCY];

export const DEFAULT_COMPANY_LOGO = 'default-company-logo.png';

export const EA_LICENCE_NUMBER = '20C0196';

export const SOCIAL_MEDIA_URL_PREFIXES = {
  instagram: 'www.instagram.com/',
  facebook: 'www.facebook.com/',
  linkedin: 'www.linkedin.com/company/',
  twitter: 'www.twitter.com/',
};

export const CONTACT_URLS: {
  [country in SupportedCountry]?: string;
} = {
  id: 'https://global.glints.com/id/contact-us/',
  sg: 'https://global.glints.com/contact-us/',
};

export const CONTACT_LINK = CONTACT_URLS?.[COUNTRY];

export const URLS: {
  signup: {
    service: {
      talentHunt: {
        [country in SupportedCountry]?: string;
      } & { default: string };
      talentHub: {
        [country in SupportedCountry]?: string;
      } & { default: string; tw: string };
    };
  };
  landingPage: {
    service: {
      talentHunt: {
        [country in SupportedCountry]?: string;
      } & { default: string };
      talentHub: {
        [country in SupportedCountry]?: string;
      } & { default: string };
    };
  };
} = {
  signup: {
    service: {
      talentHunt: {
        default: 'https://employers.glints.id/#why-th',
        vn: 'https://talenthunt.glints.com/vncompanysignup/',
        sg: 'https://global.glints.com/sg/talenthunt/',
        tw: 'https://global.glints.com/companysignup/tw',
      },
      talentHub: {
        tw: 'https://global.glints.com/companysignup/tw',
        default: 'https://global.glints.com/#lp-pom-form-948',
      },
    },
  },
  landingPage: {
    service: {
      talentHunt: {
        default: 'https://talenthunt.glints.com/',
        tw: 'https://talenthunt.glints.tw/',
      },
      talentHub: {
        default: 'https://global.glints.com/',
        tw: 'https://global.glints.com/cn/',
        hk: 'https://global.glints.com/hk/',
      },
    },
  },
};

export const TALENT_HUNT_SIGNUP_LINK =
  URLS.signup.service.talentHunt?.[COUNTRY] ??
  URLS.signup.service.talentHunt.default;

export const BREAKPOINTS = {
  bp320: 320,
  bp480: 480,
  bp640: 640,
  bp768: 768,
  bp1024: 1024,
  bp1260: 1260,
  bp1440: 1440,
};

// TODO: Implement logic in glints-api to get top searches.
export const TOP_SEARCHES: TopSearches = {
  locations: {
    default: [
      {
        city: {
          id: '28904',
          name: 'Jakarta',
        },
        country: {
          code: 'ID',
          name: 'Indonesia',
        },
      },
      {
        city: {
          id: '127215',
          name: 'Singapore',
        },
        country: {
          code: 'SG',
          name: 'Singapore',
        },
      },
      {
        city: {
          id: '132462',
          name: 'Hà Nội',
        },
        country: {
          code: 'VN',
          name: 'Vietnam',
        },
      },
      {
        city: {
          id: '27585',
          name: 'Batam',
        },
        country: {
          code: 'ID',
          name: 'Indonesia',
        },
      },
      {
        city: {
          id: '116450',
          name: 'Taipei',
        },
        country: {
          code: 'TW',
          name: 'Taiwan',
        },
      },
    ],
    get() {
      return (
        TOP_SEARCHES.locations?.[COUNTRY] || TOP_SEARCHES.locations.default
      );
    },
  },
  jobCategories: {
    default: [
      {
        id: 1,
        name: 'Software Engineering',
      },
      {
        id: 2,
        name: 'Data Science',
      },
      {
        id: 7,
        name: 'Business Development / Sales',
      },
      {
        id: 5,
        name: 'Marketing',
      },
      {
        id: 3,
        name: 'Design',
      },
    ],
    get() {
      return (
        TOP_SEARCHES.jobCategories[COUNTRY] ||
        TOP_SEARCHES.jobCategories.default
      );
    },
  },
  schools: {
    default: [
      {
        id: 'f31a99c1-f46e-4bdb-a027-c06a68adf21d',
        name: 'National University of Singapore',
      },
      {
        id: '9f1ddf40-737c-4f55-b322-62262fad9f04',
        name: 'National Technological University',
      },
      {
        id: '3cc25aa7-db57-494f-a37e-27b5f6a4dc25',
        name: 'Singapore Management University',
      },
      {
        id: 'a3cc0b82-2afb-4262-8a05-f97b1aa9b1ac',
        name: 'Singapore University of Technology and Design',
      },
    ],
    [COUNTRY_DOMAINS.id]: [
      {
        id: 'ada46c28-4884-4f72-b5a6-200c6ea911e0',
        name: 'Universitas Indonesia',
      },
      {
        id: '5f5ca82f-856c-4f4d-9d7b-8f464491e209',
        name: 'Binus University',
      },
    ],
    get() {
      return TOP_SEARCHES.schools[COUNTRY] || TOP_SEARCHES.schools.default;
    },
  },
  skills: {
    default: [
      {
        id: '75a4e3f8-48b9-43ab-8044-7e97027dd63a',
        name: 'Business Development',
      },
      {
        id: 'ede826c4-7e54-4982-a4df-9d38f9e09210',
        name: 'Ruby on Rails',
      },
      {
        id: '104795e3-a3bc-4ba1-b6ec-ce9be6d5b120',
        name: 'JavaScript',
      },
      {
        id: '5a303b64-cc67-4bd0-a0a4-0007b96e761c',
        name: 'iOS Development',
      },
      {
        id: '852a65e4-1484-4a9c-8cac-477f405bb335',
        name: 'Web Design',
      },
    ],
    get() {
      return TOP_SEARCHES.skills[COUNTRY] || TOP_SEARCHES.skills.default;
    },
  },
};

type TopSearches = {
  locations: {
    [country in SupportedCountry]?: TopLocationsSearchResult[];
  } & {
    get(): TopLocationsSearchResult[] | undefined;
  };
  jobCategories: {
    [country in SupportedCountry]?: TopJobCategoriesSearchResult[];
  } & {
    get(): TopJobCategoriesSearchResult[] | undefined;
  };
  schools: {
    [country in SupportedCountry]?: TopSchoolsSearchResult[];
  } & {
    get(): TopSchoolsSearchResult[] | undefined;
  };
  skills: {
    [country in SupportedCountry]?: TopSkillsSearchResult[];
  } & {
    get(): TopSkillsSearchResult[] | undefined;
  };
};

type TopLocationsSearchResult = {
  city: {
    id: string;
    name: string;
  };
  country: {
    code: string;
    name: string;
  };
};

type TopJobCategoriesSearchResult = {
  id: number;
  name: string;
};

type TopSchoolsSearchResult = {
  id: string;
  name: string;
};

type TopSkillsSearchResult = {
  id: string;
  name: string;
};

export const SKILL_NAME_LIMIT_NUMBER = 25;
export const APPLICATIONS_PER_PAGE_LIMIT = 10;

export const CANDIDATE_STATUSES = {
  I_AM_LOOKING_FOR_JOB: 'I am looking for a job',
  OPEN_FOR_OPPORTUNITIES: 'Open for opportunities',
  I_AM_NOT_LOOKING_FOR_A_JOB: 'I am not looking for a job',
};

export const CANDIDATE_STATUSES_LABELS = defineMessages({
  I_AM_LOOKING_FOR_JOB: {
    id: 'common.constants.looking.for.job',
    defaultMessage: 'I am looking for a job',
  },
  OPEN_FOR_OPPORTUNITIES: {
    id: 'common.constants.open.for.opportunities',
    defaultMessage: 'Open for opportunities',
  },
  I_AM_NOT_LOOKING_FOR_A_JOB: {
    id: 'common.constants.not.looking.for.job',
    defaultMessage: 'I am not looking for a job',
  },
});

export type CandidateStatus = keyof typeof CANDIDATE_STATUSES;

export const STATES = {
  ERROR: 'error',
  LOADING: 'loading',
  SUCCESS: 'success',
};

export const MARKETING_PARAMS_NAMESPACE = 'employerMarketingParams';

export const MARKETING_PARAMS_WHITELIST_PATTERNS = [
  '^utm$',
  '^utm_.*$',
  '^source$',
  '^referral.*$',
  '^ref$',
];

export const JOB_STATUS = {
  CLOSED: 'CLOSED',
  OPEN: 'OPEN',
  IN_REVIEW: 'IN_REVIEW',
};

export enum JOB_STATUS_BREAKDOWN {
  CLOSED = 'CLOSED',
  DRAFTS = 'DRAFTS',
  IN_REVIEW = 'IN_REVIEW',
  OPEN = 'OPEN',
}

export type JobStatus = keyof typeof JOB_STATUS;

export const USER_TYPES = {
  USER: 'USER',
  SYSTEM: 'SYSTEM',
  TALENTHUNT_PLUS: 'TALENTHUNT+',
};

export const TALENT_HUB_COUNTRY_CODE = {
  sg: 'sg',
  id: 'id',
};

export const TALENT_HUNT_COUNTRY_CODE = {
  id: 'id',
  vn: 'vn',
  tw: 'tw',
};

export enum USER_ROLES {
  CANDIDATE = 'CANDIDATE',
  COMPANY = 'COMPANY',
  SUPER_ADMIN = 'SUPER_ADMIN',
  RECRUITER = 'RECRUITER',
  EMPLOYER_SERVICE_REP = 'EMPLOYER_SERVICE_REP',
  GROUP_ADMIN = 'GROUP_ADMIN',
}

export const listOfSocialNetworkName = {
  Facebook: 'Facebook',
  Twitter: 'Twitter',
  Instagram: 'Instagram',
  LinkedIn: 'LinkedIn',
  Behance: 'Behance',
  GitHub: 'GitHub',
  CodePen: 'CodePen',
  Vimeo: 'Vimeo',
  Youtube: 'Youtube',
};

export const EXPERIENCE_TYPE = {
  WORK: 'WORK',
  EDUCATION: 'EDUCATION',
  CERTIFICATE: 'CERTIFICATE',
  INTERNSHIP: 'INTERNSHIP',
  AWARD: 'AWARD',
  CCA: 'CCA',
} as const;

export type EXPERIENCE_TYPE =
  (typeof EXPERIENCE_TYPE)[keyof typeof EXPERIENCE_TYPE];

export const GROUP_MEMBERSHIP_STATUS = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
};

export const CANDIDATE_STATUS = {
  I_AM_LOOKING_FOR_JOB: 'I_AM_LOOKING_FOR_JOB',
  OPEN_FOR_OPPORTUNITIES: 'OPEN_FOR_OPPORTUNITIES',
  I_AM_NOT_LOOKING_FOR_A_JOB: 'I_AM_NOT_LOOKING_FOR_A_JOB',
};

export const JOB_TYPE = {
  FULL_TIME: 'FULL_TIME',
  PART_TIME: 'PART_TIME',
  PROJECT_BASED: 'PROJECT_BASED',
  INTERNSHIP: 'INTERNSHIP',
  DAILY: 'DAILY',
  CONTRACT: 'CONTRACT',
};

export type JOB_TYPE = (typeof JOB_TYPE)[keyof typeof JOB_TYPE];

export const JOB_TYPE_LABEL = defineMessages({
  [JOB_TYPE.FULL_TIME]: {
    id: 'text-full-time',
    defaultMessage: 'Full-time',
  },
  [JOB_TYPE.PART_TIME]: {
    id: 'text-part-time',
    defaultMessage: 'Part-time',
  },
  [JOB_TYPE.PROJECT_BASED]: {
    id: 'text-project-based',
    defaultMessage: 'Freelance',
  },
  [JOB_TYPE.INTERNSHIP]: {
    id: 'text-internship',
    defaultMessage: 'Internship',
  },
  [JOB_TYPE.DAILY]: {
    id: 'text-daily',
    defaultMessage: 'Daily',
  },
  [JOB_TYPE.CONTRACT]: {
    id: 'interactive-contract',
    defaultMessage: 'Contract',
  },
});

export const citiesWithSubDivisions = [
  { id: 28904, name: 'Jakarta' },
  { id: 127215, name: 'Singapore' },
  { id: 132294, name: 'Ho Chi Minh City' },
];

export const TERMS_AND_CONDITIONS_FILENAMES: {
  [country in SupportedCountry]?: string;
} = {
  sg: 'GlintsTermsAndConditions.pdf',
  vn: 'terms-and-conditions-vn.pdf',
  tw: 'terms-and-conditions-tw.pdf',
};

const getTermsAndConditionsUrl = (
  getAssetUrl: GetAssetURLCallback,
  countryCode?: SupportedCountry
) => {
  const fileName =
    TERMS_AND_CONDITIONS_FILENAMES[countryCode || 'sg'] ||
    TERMS_AND_CONDITIONS_FILENAMES.sg;

  if (!fileName) return null;

  return getAssetUrl(fileName, 'docs');
};

export const useGetTermsAndConditionsUrl = () => {
  const { getAssetURL } = useGetAssetURL();

  return {
    getTermsAndConditionsUrl: (countryCode?: SupportedCountry) =>
      getTermsAndConditionsUrl(getAssetURL, countryCode),
  };
};

export const BUILDER_MODEL_TYPE = {
  PAGE: 'page',
  SECTION: 'section',
  DATA: 'data',
};

export const BUILDER_ROUTES: Array<Pick<RouteProps, 'path' | 'exact'>> = [
  { path: '/' },
  { path: '/how-talenthub-works' },
  { path: '/recruitment' },
  { path: '/managed-talent' },
  { path: '/job-search' },
  { path: '/fee-structure' },
  { path: '/talent-pool' },
  { path: '/startup-offer' },
  { path: '/remote-hiring' },
  { path: '/book-a-call' },
  { path: '/claim-startup-offer' },
  { path: '/events' },
  { path: '/ebooks', exact: false },
  { path: '/faq' },
  { path: '/success-stories', exact: false },
  { path: '/best-employers-award', exact: false },
  { path: '/enterprise' },
  { path: '/hiring-guide', exact: false },
  { path: '/employee-cost-calculator' },
  { path: '/solutions', exact: false },
  { path: '/interview-guideline' },
  { path: '/survey-karyawan' },
  { path: '/surat-kontrak-kerja' },
  { path: '/surat-keterangan-kerja' },
  { path: '/BD-job-description' },
  { path: '/SOP-rekrutmen' },
  { path: '/Kontrak-PKWTT' },
  { path: '/Kontrak-PKWT' },
  { path: '/360-degree' },
  { path: '/surat-peringatan' },
  { path: '/surat-penawaran' },
  { path: '/surat-penolakan' },
  { path: '/offering-letter' },
  { path: '/onboarding-karyawan' },
  { path: '/contoh-lowongan' },
];

export const EMP_BUILDER_INTEGRATION_FEATURE_FLAG = 'empBuilderIntegration';

export const MINIMUM_PASSWORD_LENGTH = 6;

export enum CompanyStatus {
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
  REJECTED = 'REJECTED',
  BLOCKED = 'BLOCKED',
  UNDER_INVESTIGATION = 'UNDER_INVESTIGATION',
}

export enum ApplicationStatus {
  NEW = 'NEW',
  IN_REVIEW = 'IN_REVIEW',
  INTERVIEWING = 'INTERVIEWING',
  ASSESSMENT = 'ASSESSMENT',
  OFFERED = 'OFFERED',
  HIRED = 'HIRED',
  REJECTED = 'REJECTED',
}

export const APPLICATION_STATUS_ORDER = [
  ApplicationStatus.NEW,
  ApplicationStatus.IN_REVIEW,
  ApplicationStatus.ASSESSMENT,
  ApplicationStatus.INTERVIEWING,
  ApplicationStatus.OFFERED,
  ApplicationStatus.HIRED,
  ApplicationStatus.REJECTED,
];

export enum ActivityLogAction {
  APPLIED = 'APPLIED',
  VIEWED = 'VIEWED',
  STATUS_CHANGE = 'STATUS_CHANGE',
}

export enum SORT_ORDER {
  ASC = 'ASC',
  DESC = 'DESC',
}

export const EMAIL_NOTIFIED_STATUSES = [
  ApplicationStatus.INTERVIEWING,
  ApplicationStatus.ASSESSMENT,
  ApplicationStatus.REJECTED,
];

export enum ApplicationSource {
  WEB = 'WEB',
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  UNKNOWN = 'UNKNOWN',
  TALENT_SEARCH_WEB = 'TALENT_SEARCH_WEB',
  RECOMMENDED_TALENT = 'RECOMMENDED_TALENT',
}

export const JOB_EXTENSION_ALERT_MESSAGES = defineMessages({
  extensionSuccessTitle: {
    id: 'text-job-been-extended',
    defaultMessage: 'Job has been extended',
  },
  reopenSuccessTitle: {
    id: 'interactive-job-reactivated',
    defaultMessage: 'Job Reactivated',
  },
  extensionSuccessContent: {
    id: 'text-share-job-social-media',
    defaultMessage: 'Candidates will directly be able to see this information',
  },
  reactivationSuccessContent: {
    id: 'text-now-have-available-slot',
    defaultMessage: 'Now you have {count} available job slots',
  },
  extensionFailureTitle: {
    id: 'text-job-extension-failed',
    defaultMessage: 'Job extension failed',
  },
  extensionFailureContent: {
    id: 'text-failed-extend-job-content',
    defaultMessage: 'Failed to extend this job',
  },
  reactivateJobFailureTitle: {
    id: 'text-failed-to-reactivate-job',
    defaultMessage: 'Failed to Reactivate Job',
  },
  reactivateJobFailureContent: {
    id: 'text-try-again-or-contact-us',
    defaultMessage:
      'Try again later or contact us if the problem still persists',
  },
  reactivateSubscriptionJobFailureContent: {
    id: 'interactive-you-exceeded-subscription-limit',
    defaultMessage:
      'You’ve exceeded your subscription limit. <link>View My Subscription</link> for more details.',
  },
});

export const JOB_CLOSE_ALERT_MESSAGES = defineMessages({
  closeSuccessTitle: {
    id: 'text-job-deactivated',
    defaultMessage: 'Job Deactivated',
  },
  closeSuccessContent: {
    id: 'text-manage-applicants-in-inactive',
    defaultMessage:
      'Manage applicants for this job in <b>Inactive</b> tab on <b>Dashboard</b>',
  },
  subscriptionCloseJobTitle: {
    id: 'text-now-have-available-slot',
    defaultMessage: 'Now you have {count} available job slots',
  },
});
export const DAY_MONTH_YEAR_MESSAGES = defineMessages({
  day: {
    id: 'text-count-day',
    defaultMessage:
      '{count, plural,=0 {0 days} =1 {1 day} other {{count} days}}',
  },
  month: {
    id: 'text-count-months',
    defaultMessage:
      '{count, plural,=0 {0 months} =1 {1 month} other {{count} months}}',
  },
  year: {
    id: 'text-count-years',
    defaultMessage:
      '{count, plural,=0 {0 years} =1 {1 year} other {{count} years}}',
  },
});

export const MONTH_YEAR_SHORT_MESSAGES = defineMessages({
  month: {
    id: 'text-count-months-short',
    defaultMessage: '{count}m',
  },
  year: {
    id: 'text-count-years-short',
    defaultMessage: '{count}y',
  },
});

export const JobApplicationMethodOption = {
  GLINTS: 'GLINTS',
  EXTERNAL: 'EXTERNAL',
} as const;

export type JobApplicationMethodOption =
  (typeof JobApplicationMethodOption)[keyof typeof JobApplicationMethodOption];

export const WorkArrangementOption = {
  ONSITE: 'ONSITE',
  HYBRID: 'HYBRID',
  REMOTE: 'REMOTE',
} as const;

export type WorkArrangementOption =
  (typeof WorkArrangementOption)[keyof typeof WorkArrangementOption];

export const WorkArrangementOptionsToLabels = defineMessages({
  [WorkArrangementOption.ONSITE]: {
    id: 'interactive-work-in-office',
    defaultMessage: 'Work in office',
  },
  [WorkArrangementOption.HYBRID]: {
    id: 'interactive-hybrid',
    defaultMessage: 'Hybrid',
  },
  [WorkArrangementOption.REMOTE]: {
    id: 'interactive-remote',
    defaultMessage: 'Remote',
  },
});

export enum EDUCATION_LEVEL {
  PRIMARY_SCHOOL = 'PRIMARY_SCHOOL',
  SECONDARY_SCHOOL = 'SECONDARY_SCHOOL',
  HIGH_SCHOOL = 'HIGH_SCHOOL',
  DIPLOMA = 'DIPLOMA',
  COLLEGE_DEGREE = 'COLLEGE_DEGREE',
  BACHELOR_DEGREE = 'BACHELOR_DEGREE',
  MASTER_DEGREE = 'MASTER_DEGREE',
  DOCTORATE = 'DOCTORATE',
  NO_PREFERENCE = 'NO_PREFERENCE',
}

export type TALENT_SEARCH_EDUCATION_LEVELS = Exclude<
  EDUCATION_LEVEL,
  EDUCATION_LEVEL.NO_PREFERENCE
>;

export enum GENDER {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  PREFER_NOT_TO_SHARE = 'PREFER_NOT_TO_SHARE',
  OTHER = 'OTHER',
}

export const GENDER_DISPLAY = {
  [GENDER.MALE]: {
    id: 'interactive-male',
    defaultMessage: 'Male',
  },
  [GENDER.FEMALE]: {
    id: 'interactive-female',
    defaultMessage: 'Female',
  },
  [GENDER.PREFER_NOT_TO_SHARE]: {
    id: ' interactive-other',
    defaultMessage: 'Other',
  },
  [GENDER.OTHER]: {
    id: ' interactive-other',
    defaultMessage: 'Other',
  },
};

export const EDUCATION_LEVEL_MESSAGES = defineMessages({
  [EDUCATION_LEVEL.PRIMARY_SCHOOL]: {
    id: 'interactive-primary-school',
    defaultMessage: 'Primary School',
  },
  [EDUCATION_LEVEL.SECONDARY_SCHOOL]: {
    id: 'interactive-secondary-school',
    defaultMessage: 'Secondary School',
  },
  [EDUCATION_LEVEL.HIGH_SCHOOL]: {
    id: 'interactive-high-vocational-school',
    defaultMessage: 'High School/ Vocational School',
  },
  [EDUCATION_LEVEL.DIPLOMA]: {
    id: 'interactive-diploma',
    defaultMessage: 'Diploma',
  },
  [EDUCATION_LEVEL.COLLEGE_DEGREE]: {
    id: 'interactive-college-degree',
    defaultMessage: 'College Degree',
  },
  [EDUCATION_LEVEL.BACHELOR_DEGREE]: {
    id: 'interactive-bachelors-degree',
    defaultMessage: "Bachelor's Degree",
  },
  [EDUCATION_LEVEL.MASTER_DEGREE]: {
    id: 'interactive-master-degree',
    defaultMessage: "Master's Degree",
  },
  [EDUCATION_LEVEL.DOCTORATE]: {
    id: 'interactive-doctorate',
    defaultMessage: 'Doctorate',
  },
  [EDUCATION_LEVEL.NO_PREFERENCE]: {
    id: 'interactive-no-preference',
    defaultMessage: 'No Preference',
  },
});

export enum QUESTION_FORMAT {
  YEAR_MONTH_SELECTION = 'YEAR_MONTH_SELECTION',
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
}

export const ASSET_TYPES = {
  PROFILE_PICTURE_DEFAULT: 'profile-picture-default',
  COMPANY_BANNER_PIC: 'company-banner-pic',
  DEFAULT_BANNER_PIC_LARGE: 'default-banner-pic-large',
  COMPANY_LOGO: 'company-logo',
  COMPANY_PHOTOS: 'company-photos',
  JOB_BANNER_PIC: 'job-banner-pic',
  JOB_DEFAULT_BANNER_PIC: 'job-default-banner-pic',
  PROFILE_PICTURE: 'profile-picture',
  RESUME: 'resume',
  COMPANY_LEGAL_DOCUMENT: 'company-legal-document',
  CHAT_ATTACHMENTS: 'chat-attachments',
  TEMP: 'temp', // Glints generated resume
} as const;

export type AssetType = (typeof ASSET_TYPES)[keyof typeof ASSET_TYPES];

export const Genders = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  ALL_GENDERS: 'ALL_GENDERS',
};

export type GenderKeys = (typeof Genders)[keyof typeof Genders];

export const GenderLabels = {
  MALE: 'Male',
  FEMALE: 'Female',
  ALL_GENDERS: 'All Genders',
};

export const genderOptions = [
  {
    gender: Genders.MALE,
    key: GenderLabels.MALE,
  },
  {
    gender: Genders.FEMALE,
    key: GenderLabels.FEMALE,
  },
  {
    gender: Genders.ALL_GENDERS,
    key: GenderLabels.ALL_GENDERS,
  },
];

export type GenderLabelKeys = (typeof GenderLabels)[keyof typeof GenderLabels];

export const yearsOfExperienceOptions = [
  {
    minWorkDurationInMonths: 0,
    maxWorkDurationInMonths: 12,
  },
  {
    minWorkDurationInMonths: 12,
    maxWorkDurationInMonths: 36,
  },
  {
    minWorkDurationInMonths: 36,
    maxWorkDurationInMonths: 60,
  },
  {
    minWorkDurationInMonths: 60,
    maxWorkDurationInMonths: 120,
  },
  {
    minWorkDurationInMonths: 120,
    maxWorkDurationInMonths: undefined,
  },
  {
    minWorkDurationInMonths: undefined,
    maxWorkDurationInMonths: undefined,
  },
] as const;

export type YearsOfExperienceOptionsProps =
  (typeof yearsOfExperienceOptions)[number];

export const yearsOfExperienceOptionsMessages = defineMessages({
  lessThanAYear: {
    id: 'interactive-less-than-a-year',
    defaultMessage: 'Less than a year',
  },
  noPreference: {
    id: 'interactive-no-preference',
    defaultMessage: 'No Preference',
  },
  hasYearRange: {
    id: 'interactive-years-of-experience-range',
    defaultMessage: '{minCount} to {maxCount} Years',
  },
  tenYearsPlus: {
    id: 'interactive-ten-years-experience-plus',
    defaultMessage: '10 Years +',
  },
});

export enum NotificationChannel {
  EMAIL = 'EMAIL',
  ZALO = 'ZALO',
  WHATSAPP = 'WHATSAPP',
}

export enum NotificationCategory {
  JOB = 'Job',
  APPLICATION = 'Application',
  CONNECTION = 'Connection',
  RECOMMENDATION = 'Recommendation',
  FOLLOWER = 'Follower',
}

export const CHAT_ENABLED_COUNTRIES = ['id', 'vn'];

export const WHATSAPP_CHAT_SUPPORT_ENABLED_COUNTRIES = ['id'];

export const IS_CHAT_ENABLED = CHAT_ENABLED_COUNTRIES.includes(config.COUNTRY);

export const EXPORT_CSV_ALLOWED_COUNTRIES = [COUNTRY_DOMAINS.id];

export const applicationStatusMessages: Record<
  ApplicationStatus,
  MessageDescriptor
> = {
  [ApplicationStatus.NEW]: IS_CHAT_ENABLED
    ? {
        id: 'text-chat-initiated',
        defaultMessage: 'Chat Initiated',
      }
    : {
        id: 'interactive-pending',
        defaultMessage: 'Pending',
      },
  [ApplicationStatus.IN_REVIEW]: IS_CHAT_ENABLED
    ? {
        id: 'text-in-communication',
        defaultMessage: 'In Communication',
      }
    : {
        id: 'interactive-processed',
        defaultMessage: 'Processed',
      },
  [ApplicationStatus.ASSESSMENT]: {
    id: 'text_application-status_skill-test',
    defaultMessage: 'Skill Test',
  },
  [ApplicationStatus.INTERVIEWING]: {
    id: 'interactive-interviewing',
    defaultMessage: 'Interviewing',
  },
  [ApplicationStatus.OFFERED]: {
    id: 'text_application-status_offer-sent',
    defaultMessage: 'Offer Sent',
  },
  [ApplicationStatus.HIRED]: {
    id: 'interactive-hired',
    defaultMessage: 'Hired',
  },
  [ApplicationStatus.REJECTED]: IS_CHAT_ENABLED
    ? {
        id: 'text-not-suitable',
        defaultMessage: 'Not Suitable',
      }
    : {
        id: 'interactive-rejected',
        defaultMessage: 'Rejected',
      },
};

export const LOCATION_LEVEL = {
  PROVINCE: 2,
  CITY: 3,
};

export const hierarchicalLocationSupportedCountryCodes = ['ID', 'VN'];

type CountryLocation = {
  id: string;
  name: string;
  code: string;
};

export const ID_COUNTRY_LOCATION: CountryLocation = {
  id: '7542a4d7-4324-4f76-ae1f-2e37ffb1198b',
  name: 'Indonesia',
  code: 'ID',
};

export const VN_COUNTRY_LOCATION: CountryLocation = {
  id: '7c10b915-18a7-4e3b-9195-33b9efaa1324',
  name: 'Vietnam',
  code: 'VN',
};

export const COUNTRY_LOCATION: Record<string, CountryLocation | undefined> = {
  ID: ID_COUNTRY_LOCATION,
  VN: VN_COUNTRY_LOCATION,
};

export const INDONESIAN_LANGUAGE = 'id';

export const JobBoostStatus = {
  ACTIVE: 'ACTIVE',
  ENDED: 'ENDED',
} as const;

export type JobBoostStatus =
  (typeof JobBoostStatus)[keyof typeof JobBoostStatus];

export const SupportedCompanyLogoFileTypes = [
  'image/jpeg',
  'image/png',
] as const;
export const SupportedCompanyLogoFileExtensions = [
  'jpg',
  'jpeg',
  'png',
] as const;
