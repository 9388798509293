import { gql } from '@apollo/client';

export const getCurrentCompanyJobSlotsSubscriptionQuery = gql`
  query getCurrentCompanyJobSlotsSubscription(
    $input: BaseEmployerCommerceInput!
    $withCSVDownload: Boolean!
  ) {
    getCurrentCompanyJobSlotsSubscription(input: $input) {
      totalConcurrentJobSlots
      activeConcurrentJobs
      expiryDateTime
      isConcurrentJobsHardLimitReached
      hasUnlimitedConcurrentJobSlots
      planType
      translatedPlanName
      hasCSVDownload @include(if: $withCSVDownload)
      hasVIPMembershipAccess
      hasJobExternalUrl
    }
  }
`;
