import { useCallback, useEffect, useMemo } from 'react';
import { isEqualWith } from 'lodash-es';

import { CurrentCompanyJobSlotsSubscription } from '../interfaces/entities';
import { usePrevious } from '.';
import { useHandleUpdateUserCompanyJobPostModeState } from './useHandleUpdateUserCompanyJobPostModeState';

type Props = {
  currentCompanyJobSlotsSubscription?: CurrentCompanyJobSlotsSubscription;
  sessionCompanyId: string | undefined;
};

/**
 * This hook is used to auto update the current session company data in the [userCompanies] with the latest job slots subscription or pay per post info,
 * when the hooks data props.
 */
export const useUpdateUserCompanyJobPostModeState = ({
  currentCompanyJobSlotsSubscription,
  sessionCompanyId,
}: Props) => {
  const companyJobPostData = useMemo(
    () => ({
      currentCompanyJobSlotsSubscription,
    }),
    [currentCompanyJobSlotsSubscription]
  );
  const {
    handleUpdateUserCompanyJobPostModeState:
      handleUpdateUserCompanyJobSlotSubscriptionState,
  } = useHandleUpdateUserCompanyJobPostModeState();
  const updateUserCompanyDataWithLatestJobSlotsSubscription =
    useCallback(() => {
      handleUpdateUserCompanyJobSlotSubscriptionState({
        currentCompanyJobSlotsSubscription,
        sessionCompanyId,
      });
    }, [
      currentCompanyJobSlotsSubscription,
      handleUpdateUserCompanyJobSlotSubscriptionState,
      sessionCompanyId,
    ]);

  const prevCompanyJobPostData = usePrevious(companyJobPostData);

  useEffect(() => {
    if (isEqualWith(companyJobPostData, prevCompanyJobPostData)) {
      return;
    }
    updateUserCompanyDataWithLatestJobSlotsSubscription();
  }, [
    currentCompanyJobSlotsSubscription,
    companyJobPostData,
    updateUserCompanyDataWithLatestJobSlotsSubscription,
    prevCompanyJobPostData,
  ]);
};
