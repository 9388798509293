import { FieldFunctionOptions, TypePolicies } from '@apollo/client';

export const typePolicies: TypePolicies = {
  Company: {
    fields: {
      jobs: {
        keyArgs: false,
        merge(existing: any[], incoming: any[], options: FieldFunctionOptions) {
          const { args, readField } = options;
          const merged = existing ? existing.slice(0) : [];

          const existingIdSet = new Set(
            merged.map(job => readField('id', job))
          );

          incoming = incoming.filter(
            job => !existingIdSet.has(readField('id', job))
          );

          if (!incoming) return merged;

          if (!args) return merged.concat(incoming);

          const { data } = args;
          const { offset = 0 } = data;
          for (let i = 0; i < incoming.length; ++i) {
            merged[offset + i] = incoming[i];
          }
          return merged;
        },
      },
    },
  },
  CompanyVerificationInfo: {
    merge: (_: unknown, incoming: unknown) => incoming,
  },
  SubscribePendingPointsTopUpOrderCountOutput: {
    keyFields: ['companyId'],
  },
  TalentSearchChatBalance: {
    keyFields: ['companyId'],
  },
  GetBookmarksOutput: {
    keyFields: false,
  },
  GetUnlockedTalentsOutput: {
    keyFields: false,
  },
  GetCreditPurchaseOrderHistoryOutput: {
    keyFields: false,
  },
  Query: {
    fields: {
      getBookmarkedTalents: {
        merge(_: any, incoming: unknown) {
          return incoming;
        },
      },
      getUnlockedTalents: {
        merge(_: any, incoming: unknown) {
          return incoming;
        },
      },
      isPremiumJob: {
        merge(_: any, incoming: unknown) {
          return incoming;
        },
      },
      getCreditPurchaseOrderHistory: {
        keyArgs: false, // we maintain a single cache for all credit purchase order history
        merge(_: any, incoming: any) {
          return incoming;
        },
      },
    },
  },
};
