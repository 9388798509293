import React, { ReactNode } from 'react';

import useChannelUpdateEventListener from '../hooks/useChannelUpdateEventListener';
import useMessageCreateEventListener from '../hooks/useMessageCreateEventListener';
import useMessageReadEventListener from '../hooks/useMessageReadEventListener';
import useMessageUpdateEventListener from '../hooks/useMessageUpdateEventListener';
import useReconnectedEventListener from '../hooks/useReconnectedEventListener';

type Props = {
  children: ReactNode;
};

export default function WSEventListenerWrapper({ children }: Props) {
  useMessageReadEventListener();
  useMessageUpdateEventListener();
  useChannelUpdateEventListener();
  useMessageCreateEventListener();
  useReconnectedEventListener();

  return <>{children}</>;
}
