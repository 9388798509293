import { apm } from '@elastic/apm-rum';
import { AnyAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { createAction } from 'redux-actions';

import { ApolloClientInstanceType } from '../apollo-client';
import { USER_ROLES } from '../common/constants';
import { preloadCompanyById } from '../common/hooks/useGetCompanyById';
import { Company } from '../common/interfaces/entities';
import { TrackingService } from '../common/tracking/trackingService';
import { GlintsChatClient } from '../modules/GlintsChat/client/GlintsChatClient';
import { getIsChatV2Enabled } from '../modules/GlintsChat/selectors';
import { applyFeatures } from '../modules/Platform';
import { UnleashFeatureNames } from '../modules/Unleash/featureNames';
import { getCurrentCompany } from '../selectors/company';
import { getIsFeatureEnabled } from '../selectors/features';
import { getSessionCompany, getSessionCompanyId } from '../selectors/session';
import { getUser, getUserRole } from '../selectors/user';
import { RootState } from '../store';
import { fetchCompany } from './company';
import { fetchFeatures } from './features';
import { updateUserCompanies } from './user/companies';
import { fetchMe } from './user/me';

export const Actions = {
  SET_SESSION_TOKEN: 'glints/session/SET_SESSION_TOKEN',
  SET_CHAT_TOKEN: 'glints/session/SET_CHAT_TOKEN',
  CLEAR_ONE_TIME_TOKEN: 'glints/session/CLEAR_ONE_TIME_TOKEN',
  SET_SESSION_COMPANY: 'glints/session/SET_SESSION_COMPANY',
  SET_SESSION_GROUP: 'glints/session/SET_SESSION_GROUP',

  REQUEST_INITIALIZE_SESSION: 'glints/session/REQUEST_INITIALIZE_SESSION',
  RECEIVE_INITIALIZE_SESSION: 'glints/session/RECEIVE_INITIALIZE_SESSION',

  UPDATE_MARKETING_METADATA: 'glints/session/UPDATE_MARKETING_METADATA',

  SET_SCOPED_TOKEN_ACTION_COMPLETED:
    'glints/session/SET_SCOPED_TOKEN_ACTION_COMPLETED',
  SET_ONE_TIME_TOKEN: 'glints/session/SET_ONE_TIME_TOKEN',
};

export const LOGOUT = 'glints/session/LOGOUT';

export const createLogoutAction = createAction(LOGOUT);

export const setSessionToken = createAction(Actions.SET_SESSION_TOKEN);
export const setChatToken = createAction(Actions.SET_CHAT_TOKEN);
export const setOneTimeToken = createAction(Actions.SET_ONE_TIME_TOKEN);
export const clearOneTimeToken = createAction(Actions.CLEAR_ONE_TIME_TOKEN);

export const requestInitializeSession = createAction(
  Actions.REQUEST_INITIALIZE_SESSION
);
export const receiveInitializeSession = createAction(
  Actions.RECEIVE_INITIALIZE_SESSION
);

export const setSessionCompany = createAction(Actions.SET_SESSION_COMPANY);
export const setSessionGroup = createAction(Actions.SET_SESSION_GROUP);
export const updateMarketingMetadata = createAction(
  Actions.UPDATE_MARKETING_METADATA
);

export const setScopedTokenActionCompleted = createAction(
  Actions.SET_SCOPED_TOKEN_ACTION_COMPLETED
);

type APIDispatch = (
  dispatch:
    | ((dispatch: APIDispatch, getState: RootState) => Promise<void> | void)
    | AnyAction
) => void;
type CustomDispatch = APIDispatch;

export function logout(apolloClient: ApolloClientInstanceType) {
  return async (dispatch: CustomDispatch, getState: RootState) => {
    const isChatV2Enabled = getIsChatV2Enabled();
    dispatch(createLogoutAction());
    dispatch(initializeSession(apolloClient));
    dispatch(fetchFeatures() as any);
    if (isChatV2Enabled) {
      const sessionToken = getState().session.token;
      const companyId = getSessionCompanyId(getState());
      const userRole = getUserRole(getState());
      disconnectUserFromGlintsChatWS(sessionToken, companyId, userRole);
      return;
    }
  };
}

const connectUserToGlintsChatWS = async (
  sessionToken: string,
  companyId: string,
  userRole: USER_ROLES
) => {
  const glintsChatClientInstance = GlintsChatClient.getInstance(
    sessionToken,
    companyId,
    userRole
  );

  try {
    await glintsChatClientInstance.connect();
  } catch (error) {
    console.error('Error connecting to Glints Chat WS', error);
    apm.captureError(error as Error);
  }
};

const disconnectUserFromGlintsChatWS = (
  sessionToken: string,
  companyId: string,
  userRole: USER_ROLES
) => {
  const glintsChatClientInstance = GlintsChatClient.getInstance(
    sessionToken,
    companyId,
    userRole
  );

  try {
    glintsChatClientInstance.destroyInstance();
  } catch (error) {
    console.error('Error disconnecting from Glints Chat WS', error);
    apm.captureError(error as Error);
  }
};

export function initializeSession(apolloClient: ApolloClientInstanceType) {
  return async (dispatch: CustomDispatch, getState: RootState) => {
    try {
      dispatch(requestInitializeSession());
      const sessionToken = getState().session.token;
      if (sessionToken) {
        await dispatch(fetchMe());
      }

      const user = getUser(getState());
      if (user && user.id) {
        await dispatch(updateUserCompanies(user.links.companies));

        // Perform the right state transition on the state tree depending on
        // the user's role.
        const role = getUserRole(getState());
        const sessionCompany: Company | undefined =
          getSessionCompany(getState());
        const activeCompany: Company | undefined = sessionCompany
          ? sessionCompany
          : user.links.companies[0];

        await dispatch(fetchFeatures({ companyId: activeCompany?.id }) as any);

        const isSelfServeCVFinderEnabled = getIsFeatureEnabled(
          getState(),
          UnleashFeatureNames.empSelfServeCVFinder
        );

        const isChatV2Enabled = getIsChatV2Enabled();

        if (activeCompany) {
          TrackingService.updateCompanySubscriptionAndProductsProperties({
            company: {
              ...activeCompany,
              talentSearchBalance: isSelfServeCVFinderEnabled
                ? activeCompany.talentSearchBalance
                : activeCompany.currentCompanyJobSlotsSubscription
                    ?.talentSearchChats?.talentSearchChatsBalance,
            },
            user,
          });
        }

        switch (role) {
          case USER_ROLES.SUPER_ADMIN:
          case USER_ROLES.RECRUITER:
          case USER_ROLES.COMPANY:
          case USER_ROLES.EMPLOYER_SERVICE_REP:
            if (!sessionCompany && activeCompany) {
              await dispatch(fetchCompany(activeCompany.id));
              const currentCompany = getCurrentCompany(getState());
              dispatch(setSessionCompany(currentCompany));
            }

            if (!activeCompany) break;
            await preloadCompanyById(activeCompany.id, apolloClient);

            if (isChatV2Enabled) {
              await connectUserToGlintsChatWS(
                sessionToken,
                activeCompany.id,
                role
              );
            }
            break;
          case USER_ROLES.CANDIDATE:
            dispatch(logout(apolloClient));
            dispatch(push('/redirect'));
            break;
          default:
            throw new Error(`${role} is not a valid role`);
        }
      }
      dispatch(applyFeatures());
      dispatch(receiveInitializeSession());
    } catch (err) {
      dispatch(receiveInitializeSession(err));
    }
  };
}
