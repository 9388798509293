/* eslint-disable max-lines */
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router';

import { BUILDER_ROUTES } from './common/constants';
import { loadable } from './common/loadable';
import ApplicationsPageRedirect from './modules/EmployerATSV2/ApplicationsPageRedirect';
import { getIsChatV2Enabled } from './modules/GlintsChat/selectors';
import JobCopyRedirection from './modules/JobV2/JobCopyRedirection';
import { UnleashFeatureNames } from './modules/Unleash/featureNames';
import { retry } from './retry';
import { authRoutes } from './routes/authRoutes';
import Route from './routes/Route';
import { getIsFeatureEnabledInCountry } from './selectors/features';

const PendingAdminsPage = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "page-admins-pending" */ './modules/CompanyDashboard/PendingAdmins'
      )
  )
);

const CompanyEditPage = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "page-company-edit" */ './modules/CompanyEdit/CompanyEdit'
      )
  )
);

const CompanyTeamPage = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "page-company-team" */ './modules/CompanyTeam/CompanyTeamPage'
      )
  )
);

const DashboardPage = loadable(() =>
  retry(
    () => import(/* webpackChunkName: "page-dashboard" */ './modules/Dashboard')
  )
);

const Error403Page = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "page-error-403" */ './modules/ErrorPages/Error403'
      )
  )
);

const Error404Page = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "page-error-404" */ './modules/ErrorPages/Error404'
      )
  )
);

const Error500Page = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "page-error-500" */ './modules/ErrorPages/Error500'
      )
  )
);

const FAQPage = loadable(() =>
  retry(
    () => import(/* webpackChunkName: "page-faq" */ './modules/FAQ/FAQPage')
  )
);

const JobDraftRedirection = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "page-job-draft-redirection" */ './modules/JobV2/JobDraftRedirection'
      )
  )
);

const JobPostingPageV2 = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "page-job-posting-v2" */ './modules/JobV2/JobPostingPage'
      )
  )
);

const JobEditPageV2 = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "page-job-edit-v2" */ './modules/JobV2/JobEditPage'
      )
  )
);

const JobEnhancePage = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "page-job-enhance" */ './modules/JobV2/components/JobEnhancePage/JobEnhancePage'
      )
  )
);

const TermsOfUsePage = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "page-terms-of-use" */ './modules/TermsOfUse/TermsOfUse'
      )
  )
);

const CompanyCreationPage = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "page-company-creation" */ './modules/CompanyOnboarding/CompanyCreationPage/CompanyCreationPage'
      )
  )
);

const PrivacyPolicyPage = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "page-terms-of-use" */ './modules/PrivacyPolicy/PrivacyPolicy'
      )
  )
);

const ResumePage = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "page-resume" */ './modules/ResumePage/ResumePage'
      )
  )
);

const SignUpPage = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "page-signup" */ './modules/CompanyOnboarding/SignUpPage/SignUpPage'
      )
  )
);

const RedirectCandidatesPage = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "page-redirect-candidates" */ './modules/RedirectCandidatesPage/RedirectCandidatesPage'
      )
  )
);

const SettingsPage = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "page-settings" */ './modules/Settings/SettingsPage'
      )
  )
);

const EmployerATSPageV2 = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "page-employer-ats-v2" */ './modules/EmployerATSV2'
      )
  )
);

const ChatPage = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "glints-chat-page" */ './modules/GlintsChat/GlintsChatPage'
      )
  )
);

const BuilderPageLoader = loadable(() =>
  retry(() => import('./modules/Landing/BuilderPage/BuilderPageLoader'))
);

const ApplicationDetailsPage = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "page-job-posting-details" */ './modules/ApplicationDetails/ApplicationDetailsPage'
      )
  )
);

const EmptyApplicationDetailsPage = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "page-empty-applications-stage" */ './modules/ApplicationDetails/EmptyApplicationDetailsPage'
      )
  )
);

const SubscriptionPlanUpgradePage = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "subscription-plan-upgrade" */ './modules/EmployerCommerce/SubscriptionPlanUpgrade/SubscriptionPlanUpgradePage'
      )
  ).then(module => module.SubscriptionPlanUpgradePage)
);

const TalentSearchPage = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "talent-search" */ './modules/TalentSearch/TalentSearch'
      )
  )
);
const CompanyLocationUpdatePage = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "company-location-update" */ './modules/CompanyLocationUpdate/CompanyLocationUpdate'
      )
  ).then(module => module.CompanyLocationUpdatePage)
);
const CompanyReportStatusPage = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "company-report-status" */ './modules/CompanyReportStatus/CompanyReportStatusPage'
      )
  ).then(module => module.CompanyReportStatusPage)
);

const RecommendedTalentsPage = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "recommended-talents" */ './modules/RecommendedTalents/RecommendedTalentsPage'
      )
  )
);

const FeaturesPage = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "features-page" */ './modules/CreditSystem/Features/FeaturesPage'
      )
  )
);
const FeaturesHistoryPage = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "features-history-page" */ './modules/CreditSystem/FeaturesHistory/FeaturesHistoryPage'
      )
  )
);
const FeaturesOrderHistoryPage = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "features-order-history-page" */ './modules/CreditSystem/FeaturesOrderHistory/FeaturesOrderHistoryPage'
      )
  )
);

const CreditPurchaseConfirmationPage = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "credit-purchase-confirmation" */ './modules/CreditSystem/CreditPurchaseConfirmation/CreditPurchaseConfirmationPage'
      )
  )
);

// To allow non-tech folks to update landing pages, we are using a headless
// content management system (CMS) called Builder.io.
const builderLandingRoutes = BUILDER_ROUTES.map(({ path, exact = true }) => (
  <Route
    key={path as string}
    path={path}
    component={BuilderPageLoader}
    allowUnverifiedUserEmail={true}
    exact={exact}
  />
));

const Routes = () => {
  const empCanAccessMessaging = useSelector(getIsChatV2Enabled);
  const isJobCopyDisabled = useSelector(state =>
    getIsFeatureEnabledInCountry(
      state,
      UnleashFeatureNames.webEmpDisableJobCopy
    )
  );

  return (
    <Switch>
      {builderLandingRoutes}

      {authRoutes.map(authRoute => (
        <Route
          key={authRoute.path}
          path={authRoute.path}
          component={authRoute.component}
          allowUnverifiedUserEmail={authRoute.allowUnverified}
        />
      ))}

      <Route
        path="/onboarding"
        index={false}
        component={CompanyCreationPage}
        mustBeAuthenticated={true}
        allowUnverifiedUserEmail={true}
      />
      <Route
        path="/privacy-policy"
        index={false}
        component={PrivacyPolicyPage}
        allowUnverifiedUserEmail={true}
      />
      <Route
        path="/signup"
        index={false}
        component={SignUpPage}
        allowUnverifiedUserEmail={true}
      />
      <Route
        path="/job/create"
        mustBeAuthenticated={true}
        index={false}
        component={JobDraftRedirection}
        exact={true}
        desktopOnly={true}
      />

      <Route
        path="/job/edit/:jobId/reopen"
        mustBeAuthenticated={true}
        index={false}
        component={JobEditPageV2}
        desktopOnly={true}
      />

      <Route
        path="/job/edit/:jobId"
        mustBeAuthenticated={true}
        index={false}
        component={JobEditPageV2}
        desktopOnly={true}
      />
      <Route
        path="/job/draft/:draftId"
        mustBeAuthenticated={true}
        index={false}
        component={JobPostingPageV2}
        exact={true}
        desktopOnly={true}
      />
      {!isJobCopyDisabled && (
        <Route
          path="/job/copy/:jobId"
          mustBeAuthenticated={true}
          index={false}
          component={JobCopyRedirection}
          desktopOnly={true}
        />
      )}

      <Route
        path="/job/enhance/:jobId"
        mustBeAuthenticated={true}
        index={false}
        component={JobEnhancePage}
        desktopOnly={true}
      />

      <Route
        path="/dashboard"
        exact={true}
        mustBeAuthenticated={true}
        index={false}
        component={DashboardPage}
        desktopOnly={true}
      />
      <Route
        path="/dashboard/admins"
        component={PendingAdminsPage}
        mustBeAuthenticated={true}
        desktopOnly={true}
      />
      <Route
        path="/talent-search"
        component={TalentSearchPage}
        mustBeAuthenticated={true}
        desktopOnly={true}
      />
      <Route
        path="/recommended-talents"
        component={RecommendedTalentsPage}
        mustBeAuthenticated={true}
        desktopOnly={true}
        index={false}
      />
      <Route
        path="/review/:jid/candidates"
        component={ApplicationsPageRedirect}
        desktopOnly={true}
        mustBeAuthenticated={true}
      />
      <Redirect path="/review" to={'/dashboard'} exact={true} />
      <Route
        path="/manage-candidates"
        mustBeAuthenticated={true}
        desktopOnly={true}
        index={false}
        component={EmployerATSPageV2}
      />
      <Redirect path="/ats" to="/manage-candidates" />
      {empCanAccessMessaging && (
        <Route
          path="/messages"
          mustBeAuthenticated={true}
          desktopOnly={true}
          index={false}
          allowUnverifiedCompany={false}
          component={ChatPage}
        />
      )}

      <Route
        path="/companies/:companyId/edit"
        mustBeAuthenticated={true}
        desktopOnly={true}
        index={false}
        component={CompanyEditPage}
      />
      <Route
        path="/company/edit"
        mustBeAuthenticated={true}
        desktopOnly={true}
        index={false}
        component={CompanyEditPage}
      />
      <Route
        path="/company/team"
        mustBeAuthenticated={true}
        desktopOnly={true}
        index={false}
        component={CompanyTeamPage}
      />
      <Redirect path="/group" exact={true} to="/dashboard" />
      <Route
        path="/settings"
        exact={true}
        mustBeAuthenticated={true}
        desktopOnly={true}
        index={false}
        component={SettingsPage}
        allowUnverifiedUserEmail={true}
      />
      <Route
        path="/settings/:tab"
        mustBeAuthenticated={true}
        desktopOnly={true}
        index={false}
        component={SettingsPage}
        allowUnverifiedUserEmail={true}
      />
      <Route
        path="/redirect"
        component={RedirectCandidatesPage}
        allowUnverifiedUserEmail={true}
      />
      <Route
        path="/terms"
        component={TermsOfUsePage}
        allowUnverifiedUserEmail={true}
      />
      <Route
        path="/resume/:jobId/application/:applicationId"
        mustBeAuthenticated={true}
        desktopOnly={true}
        index={false}
        component={ResumePage}
      />
      <Route
        path="/faq"
        index={true}
        allowUnverifiedUserEmail={true}
        component={FAQPage}
      />
      <Route
        path="/jobs/:jobId/applications/status/:status"
        index={false}
        desktopOnly={true}
        component={EmptyApplicationDetailsPage}
        mustBeAuthenticated={true}
      />
      <Route
        path="/jobs/:jobId/applications/:applicationId"
        index={false}
        component={ApplicationDetailsPage}
        desktopOnly={true}
        allowUnverifiedCompany={false}
        mustBeAuthenticated={true}
      />
      <Route
        path="/upgrade-plan"
        index={false}
        desktopOnly={true}
        component={SubscriptionPlanUpgradePage}
        mustBeAuthenticated={true}
      />
      <Route
        path="/features"
        index={false}
        desktopOnly={true}
        exact={true}
        component={FeaturesPage}
        mustBeAuthenticated={true}
      />
      <Route
        path="/features/history"
        index={false}
        exact={true}
        component={FeaturesHistoryPage}
        mustBeAuthenticated={true}
        desktopOnly={true}
      />
      {/* TODO: FIXME: Remove this route once we fix the order history tab */}
      <Route
        path="/features/order-history"
        index={false}
        exact={true}
        component={FeaturesOrderHistoryPage}
        mustBeAuthenticated={true}
        desktopOnly={true}
      />
      <Route
        path="/credit-confirmation"
        index={false}
        exact={true}
        component={CreditPurchaseConfirmationPage}
        mustBeAuthenticated={true}
        desktopOnly={true}
      />
      <Route
        path="/company-location"
        index={false}
        component={CompanyLocationUpdatePage}
        mustBeAuthenticated={true}
        desktopOnly={true}
      />
      <Route
        path="/company-report-status"
        index={false}
        component={CompanyReportStatusPage}
        allowUnverifiedUserEmail={true}
      />
      <Route
        path="/403"
        component={Error403Page}
        allowUnverifiedUserEmail={true}
      />
      <Route
        path="404"
        component={Error404Page}
        allowUnverifiedUserEmail={true}
      />
      <Route
        path="/500"
        component={Error500Page}
        allowUnverifiedUserEmail={true}
      />
      <Route
        path="*"
        component={Error404Page}
        allowUnverifiedUserEmail={true}
      />
    </Switch>
  );
};

export default Routes;
