export const QUERY_CHANNELS_DEFAULT_INITIAL_PAGE = 1;
export const QUERY_CHANNELS_DEFAULT_PAGE_SIZE = 100;
export const QUERY_CHANNELS_DEFAULT_LAST_CHANNEL_UPDATED_AT = 0;

export const QUERY_MESSAGES_DEFAULT_LIMIT = 1000;

export const BASE_PATH = '/glints-chat-v2';
export const EMPLOYER_BASE_PATH = `${BASE_PATH}/employer`;

// WS Connection is proxied through HAPI server
export const WS_HAPI_PATH = `${BASE_PATH}/connection`;

export const DEFAULT_INIT_WS_RETRY_INTERVAL_IN_MS = 10;
export const DEFAULT_OFFLINE_WS_RETRY_INTERVAL_IN_MS = 5000;
export const DEFAULT_MAX_WS_RETRY_COUNT = 5;
