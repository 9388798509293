import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  getSessionCompanyId,
  getSessionToken,
} from '../../../selectors/session';
import { getUserRole } from '../../../selectors/user';
import { GlintsChatClient } from '../client/GlintsChatClient';

const GlintsChatClientContext = React.createContext<
  GlintsChatClient | undefined
>(undefined);

type GlintsChatClientProviderProps = {
  children: React.ReactNode;
};

export const GlintsChatClientProvider = ({
  children,
}: GlintsChatClientProviderProps) => {
  const token = useSelector(getSessionToken);
  const sessionCompanyId = useSelector(getSessionCompanyId);
  const userRole = useSelector(getUserRole);
  const client = useMemo(
    () => GlintsChatClient.getInstance(token, sessionCompanyId, userRole),
    [token, sessionCompanyId, userRole]
  );

  return (
    <GlintsChatClientContext.Provider value={client}>
      {children}
    </GlintsChatClientContext.Provider>
  );
};

export const useGlintsChatClientContext = () => {
  const context = useContext(GlintsChatClientContext);

  if (!context) {
    throw new Error(
      'useGlintsChatContext must be used within a GlintsChatProvider'
    );
  }

  return context;
};
