import { gql } from '@apollo/client';
import { createFragmentRegistry } from '@apollo/client/cache';

export const fragmentRegistry = createFragmentRegistry();

fragmentRegistry.register(gql`
  fragment activeSubscriptionInformationFragment on CompanyProductSubscription {
    id
    subscriptionStatus
    nextPaymentStatus
    nextPaymentChargeDate
    paymentService
    creditPurchaseOrderSubscriptionDetail {
      creditPurchaseOrder {
        totalPayment
        paymentUrl
        paymentService
      }
    }
  }
`);

fragmentRegistry.register(gql`
  fragment subscriptionFreeTrialOfferFragment on CompanyProductSubscriptionFreeTrialOffer {
    id
    timePeriod
    timePeriodUnit
  }
`);

fragmentRegistry.register(gql`
  fragment companyVerificationInfoFragment on CompanyVerificationInfo {
    isInitiated
    isRejected
  }
`);
