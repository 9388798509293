import { StringParam, useQueryParam } from 'use-query-params';

import { ChannelQueryParam } from '../../constants';
import { useChannelListState } from './useChannelListState';

export const useActiveChannelState = () => {
  const { getChannelById } = useChannelListState();

  const [channelId, setChannelId] = useQueryParam(
    ChannelQueryParam.CHANNEL_ID,
    StringParam
  );

  const setActiveChannelId = (id: string) => {
    setChannelId(id, 'replaceIn');
  };

  const resetActiveChannel = () => {
    setChannelId(undefined);
  };

  return {
    activeChannelId: channelId,
    activeChannel: channelId ? getChannelById(channelId) : null,
    setActiveChannelId,
    resetActiveChannel,
  } as const;
};
